import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { useEffect, useMemo, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";

const JobDetail = () => {
    const [jobData, setJobData] = useState({})
    const location = useLocation();
    const database = getDatabase();
    const { state } = location || {};

    const jobUid = useMemo(() => {
        if (state?.from) {
            return state.from;
        }

        const pathParts = location.pathname.split("/");
        return pathParts[pathParts.length - 1];
    }, [location.pathname])

    useEffect(() => {
        const jobDetailRef = ref(database, "jobs/" + jobUid);
        onValue(jobDetailRef, (snapshot) => {
            const data = snapshot.val();
            setJobData(data)
        })
    }, [jobUid])

    console.log("jobData: ", jobData)

    return (
        <>
            <Navbar />
            <div data-theme="halloween">
                <div className="flex flex-col w-[80%] mx-auto py-8">
                    <div className="text-xl">{jobData.date}</div>
                    <div className="text-4xl">{jobData.title}</div>
                    <div className="text-lg my-3">
                        <span className="p-1 mr-2" style={{ background: '#3d3d3d', borderRadius: '10px' }}>{jobData.salary}</span> -
                        <span className="p-1 mx-2" style={{ background: '#3d3d3d', borderRadius: '10px' }}> {jobData.years}</span>
                    </div>
                    <pre style={{ whiteSpace: 'pre-wrap' }} className="text-lg mt-14">{jobData.description}</pre>
                    <div className="my-10">
                        <a href={jobData.url} target="_blank">
                            <button className="btn btn-primary">
                                Apply
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobDetail;