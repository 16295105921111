import Navbar from "../components/Navbar/Navbar";
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { getDatabase, ref } from "firebase/database";
import { onValue } from "firebase/database";

function Jobs() {
  const database = getDatabase();
  const [jobsData, setjobsData] = useState([]);

  useEffect(() => {
    const all_jobs_ref = ref(database, "jobs/");
    onValue(all_jobs_ref, (snapshot) => {
      const data = snapshot.val();
      let jobs = []
      for (let d in data) {
        jobs.push({
          uid: d,
          title: data[d].title,
          company: data[d].company,
          years: data[d].years + " of experience",
          description: data[d].description,
          url: data[d].url,
          location: data[d].location
        })
        setjobsData(jobs)
      }
    })
  }, [])

  console.log("jobsData: ", jobsData)
  const [expandedJobs, setExpandedJobs] = useState([]);

  useEffect(() => {
    setExpandedJobs(jobsData.map(() => false));
  }, [jobsData]);

  const toggleDescription = (index) => {
    setExpandedJobs((prev) =>
      prev.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
    );
  };

  return (<>
    <Navbar />
    <div className="w-[90%] mx-auto my-10">
      <h1 className="mt-2 text-4xl lg:text-4xl mb-6">Recommended Jobs</h1>
      {jobsData.length > 0 && jobsData.slice().reverse().map((job, index) => (
        <div className="w-full border rounded-md p-4 flex flex-row gap-3 mx-auto mb-4 justify-between" data-theme="halloween">
          <div key={index} className="flex flex-col">
            <div className="text-md">{job.company} - {job.location} - {job.years}</div>
            <Link to={`/job-detail/${job.uid}`} state={{ uid: job.uid }}>
              <div className="text-2xl font-semibold mb-5">{job.title}</div>
            </Link>
            {/* <div className="text-xl mb-3">{job.company} - {job.years}</div> */}
            <div>
              {expandedJobs[index] ? (
                <pre style={{ whiteSpace: 'pre-wrap' }}>{job.description}</pre>
              ) : (
                <pre style={{ whiteSpace: 'pre-wrap' }}>{job.description.slice(0, 100)}...</pre>
              )}
            </div>
            <button
              onClick={() => toggleDescription(index)}
              className="mt-2 underline cursor-pointer text-left"
            >
              {expandedJobs[index] ? 'Show Less' : 'Read More'}
              <FontAwesomeIcon icon={expandedJobs[index] ? faAnglesUp : faAnglesDown} className="mx-2" />
            </button>
          </div>
          <div>
            <a href={job.url} target="_blank">
              <button className="btn btn-primary">
                Apply
              </button>
            </a>
          </div>
        </div>
      ))}
    </div>
  </>
  )
}

export default Jobs