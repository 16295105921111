/*eslint-env es6*/
import React, { useEffect } from "react";
import useState from "react-usestateref";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import CodeEditorWindow from "../components/CodeEditorWindow";
import LanguagesDropdown from "../components/LanguagesDropdown";
import outputDetails from "../components/OutputDetails";

import ThemeDropdown from "../components/ThemeDropdown";
import { useLocation } from "react-router-dom";
import TestCase from "../components/TestCase";
import Spinner from "../components/Spinner";

import { getDatabase, ref, set, push } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useAuthStatus } from "../hooks/useAuthStatus";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import AceEditor from "react-ace";
import RoomSpinner from "../components/RoomSpinner";

const javascriptDefault = ``;

const Landing = ({ ques_id }) => {
  const auth = getAuth();
  const database = getDatabase();
  const [code, setCode] = useState(javascriptDefault);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const location = useLocation();
  const [editor_mode, seteditor_mode] = useState("c_cpp");
  // // // console.log(location.state)
  // // // console.log(ques_id)

  if (location.state) {
    // // // console.log("else if")
    var { id } = location.state;
  } else {
    // // // console.log("else")
    var id = ques_id;
  }
  // // // console.log("Id after if else:", id)
  const [output_Data, setoutput_Data] = useState([]);
  const [curr_status, setcurr_status] = useState(false);
  const [show_output, setshow_output] = useState(false);
  const [error, seterror] = useState("");
  const [tc_failed, settc_failed] = useState(false);
  const [failed_testcase, setfailed_testcase] = useState({});
  const [outputs, setOutputs] = useState([]);
  const [errors, setErrors] = useState({});
  const [runtime_error, setRuntime_error] = useState(false);
  const [is_error, setis_error] = useState(false);
  const [show_output_arr, setshow_output_arr] = useState(false);
  const [testCasePassedCount, setTestCasePassedCount] = useState(0);
  const [totalTestCases, setTotalTestCases] = useState(0);
  const [show_console, setshow_console] = useState(false);
  const [runAllVar, setrunAllVar] = useState(false)

  const { loggedIn, checkingStatus } = useAuthStatus();
  let solvedStatusRef = "";
  if (loggedIn) {
    solvedStatusRef = ref(database, "solved/" + auth.currentUser.uid);
  }

  const ques_inputs = [
    {
      ques_id: 1,
      inputs: ["2\n 33 44", "3\n 11 22 10", "2\n 2 3", "4\n 10 10 10 10"],
      outputs: ["77", "43", "5", "40"],
    },
    {
      ques_id: 2,
      inputs: ["2\n 33 44", "3\n 11 22 10"],
      outputs: ["77", "43"],
    },
  ];

  const [question, setquestion] = useState({});
  const [question_inputs, setquestion_inputs] = useState([]);
  const [question_outputs, setquestion_outputs] = useState([]);
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    axios
      .get("https://ed-tech-backend-eight.vercel.app/problemsInfo/" + id, {
        mode: "no-cors",
      })
      .then((response) => {
        setquestion(response.data);
      });

    axios
      .get("https://ed-tech-backend-eight.vercel.app/problems/" + id, {
        mode: "no-cors",
      })
      .then((response) => {
        //setquestion(response.data);
        //setquestion_inputs(response.data.input_list);
        const data = JSON.parse(response.data.input_list);
        setquestion_inputs(data);

        const data2 = JSON.parse(response.data.output_list);
        setquestion_outputs(data2);
        setisloading(false);
      });
  }, []);

  useEffect(() => {
  }, [tc_failed]);

  const sample_input = question.sample_input;
  const sample_input_split = [];
  // String(sample_input).split("\n").map((si) => (
  //   sample_input_split.push(si)
  // ))

  const sample_output = question.sample_output;

  const [user_Outputs, setuser_Outputs] = useState([]);
  const onSelectChange = (sl) => {
    setLanguage(sl);
    if (sl.id === 54) {
      seteditor_mode("c_cpp");
    } else if (sl.id === 62) {
      seteditor_mode("java");
    } else {
      seteditor_mode("python");
    }
  };

  const onChange = (data) => {
    setCode(data);
    // switch (action) {
    //   case "code": {
    //     setCode(data);
    //     break;
    //   }
    //   default: {
    //     console.warn("case not handled!", action, data);
    //   }
    // }
  };

  const handleCompile = (inp, index) => {
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(inp),
      cpu_time_limit: language.runtime,
      memory_limit: 512000,
      cpu_extra_time: "0.0",
    };

    const options = {
      method: "POST",
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        //// // console.log("res.data", response.data);
        const token = response.data.token;
        const res = checkStatus(token, index);
        return res;
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        let status = err.response.status;
        if (status === 429) {

          showErrorToast(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
            10000
          );
        }
        setProcessing(false);
      });
  };

  const output_arr = [];
  for (let i = 0; i < 25; i++) {
    output_arr.push(0);
  }
  const expected_outputs = [];

  {
    question_outputs.map((qo) => expected_outputs.push(qo));
  }

  const input_arr = [];
  {
    question_inputs.map((qi) => input_arr.push(qi));
  }

  const checkOutput = (output_Data) => {
    setshow_output(true);
  };

  let status = "";
  const add_output = (data, index) => {
    if (data.status.id === 5) {
      output_arr[index] = "Time Limit Exceeded";
      settc_failed(true);
      // setProcessing(false)
      output_Data.push({
        index: index,
        Inputs: input_arr[index],
        User_Output: "TLE",
        Expec: expected_outputs[index],
        status: data.status.description,
        time: data.time,
        check_status: "Time Limit Exceeded",
        statusCode: data.status.id,
      });
      return "ab";
    } else if (data.status.id === 6) {
      output_arr[index] = atob(data.compile_output);
      settc_failed(true);
      // setProcessing(false)
      output_Data.push({
        index: index,
        Inputs: input_arr[index],
        User_Output: "Compilation Error",
        Expec: expected_outputs[index],
        status: data.status.description,
        time: data.time,
        check_status: "Compilation Error",
        statusCode: data.status.id,
      });
      return "ab";
    } else if (data.status.id === 3) {
      output_arr[index] = atob(data.stdout);
      status = "";
      if (
        String(output_arr[index]).replace("\n", "") ===
        String(expected_outputs[index]).replace("\n", "")
      ) {
        status = "Test Case Passed";
      } else {
        status = "Test Case Failed";
        settc_failed(true);
        return "ab";
      }

      output_Data.push({
        index: index,
        Inputs: input_arr[index],
        User_Output: output_arr[index],
        Expec: expected_outputs[index],
        status: data.status.description,
        time: data.time,
        check_status: status,
        statusCode: data.status.id,
      });
      if (output_Data.length === expected_outputs.length) {
        setProcessing(false);
        setcurr_status(true);
        setshow_output(true);
      }
    } else {
      seterror(atob(data.stderr));
      setshow_output(true);
      setProcessing(false);
      output_arr[index] = atob(data.stderr);
      settc_failed(true);

      output_Data.push({
        index: index,
        Inputs: input_arr[index],
        User_Output: "Error",
        Expec: expected_outputs[index],
        status: data.status.description,
        time: data.time,
        check_status: atob(data.stderr),
        statusCode: data.status.id,
      });
    }
    return "ab";
  };

  const checkStatus = async (token, index) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token, index);
          //// // console.log("Check status");
        }, 2000);
        return;
      } else {
        const res = add_output(response.data, index);
        return res;
      }
    } catch (err) {
      //// // console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  const handlefailed_tc = (inp, index) => {
    //// // console.log("I am in handle failed");
    settc_failed(true);
    //// // console.log("Input array[Index] : ", input_arr[index]);
    //// // console.log(tc_failed);
    setProcessing(false);
    return;
  };

  const submitCode = async (code, runall) => {
    var language_id = language.id;
    var language_runtime = language.runtime;
    const data = {
      quesId: id,
      decodedCode: btoa(code),
      languageId: language_id,
      languageRuntime: language_runtime,
      runall: runall
    }
    // console.log("Code : ", btoa(code))

    const res = await axios.post('https://ed-tech-backend-eight.vercel.app/submitCode/', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log("res ", res)
    let status = res["data"]["status"];
    // console.log("status : ", status)
    if (status === "ALL PASSED") {
      // console.log("ALL PASSED")
      status = "Test Case Passed";
      setcurr_status(true);
      setshow_output(true);
      setProcessing(false);
      //// // console.log("Show Output : ", show_output);
      //// // console.log(output_Data);
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: true,
          name: question.title,
          id: id,
        });
      }
      // updateCount();
      return
    } else if (status === "WRONG ANSWER") {
      const [input, userOutput, expectedOutput] = res["data"]["failedTestCase"];

      // let input = res["failedTestCase"][0]
      // let userOutput = res["failedTestCase"][1]
      // let expectedOutput = res["failedTestCase"][2]

      setProcessing((processing) => {
        const updatedProcessing = !processing;
        // // console.log("processing:", updatedProcessing);
        return updatedProcessing;
      });

      settc_failed((tc_failed) => {
        const updatedTcFailed = true;
        // // console.log("tc_failed:", updatedTcFailed);
        return updatedTcFailed;
      });

      // settc_failed(prevtc => ({...prevtc , tc_failed : true}));

      setshow_output((show_output) => {
        const updatedShowOutput = !show_output;
        // // console.log("show_output:", updatedShowOutput);
        return updatedShowOutput;
      });

      // setshow_output(show_output => ({...show_output, show_output: true}));

      setfailed_testcase({
        Input: input,
        User_Output: userOutput.replace("\n", ""),
        Expected_output: expectedOutput,
        Status: "Wrong Answer",
      });
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: false,
          name: question.title,
          id: id,
        });
      }
      return;
    } else {
      setProcessing(processing => {
        const updatedProcessing = !processing;
        // // console.log("processing:", updatedProcessing);
        return updatedProcessing;
      });

      setis_error(true);
      // // console.log("is_error:", true);
      // // console.log("faied : ", res["failedTestCase"])
      const [input, output, expected] = res["data"]["failedTestCase"];
      setErrors({
        Input: input,
        description: res["data"]["error"],
        Standard_Error: res["data"]["stderr"]
          ? res["data"]["stderr"]
          : "nullValue",
      });
      setshow_output(true);
      // // console.log("show_output:", true);

      settc_failed(false)
      // // console.log(tc_failed)

      setRuntime_error(true);
      // // console.log("Runtime_error:", true);
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: false,
          name: question.title,
          id: id,
        });
      }
      return;
    }
    return;
  }

  const compile = async (code, runall) => {
    if (code === "") {
      showErrorToast("Please Add some code before submitting!");
      return;
    }

    setrunAllVar(runall)

    setProcessing((prevProcessing) => {
      const updatedProcessing = { ...prevProcessing, processing: true };
      // // console.log("processing:", updatedProcessing);
      return updatedProcessing;
    });

    setshow_console(true);
    // // console.log("show_console:", show_console);

    setshow_output(false);
    // // console.log("show_output:", show_output);

    setcurr_status(false)
    // // console.log("Current StatuS : ", curr_status)

    setRuntime_error(false)
    // // console.log("runtime error = ", runtime_error)

    setfailed_testcase({})
    // // console.log("failed test case :", failed_testcase)

    seterror("");
    // // console.log("error:", "");

    setTotalTestCases((totalTestCases) => {
      const updatedTotalTestCases = {
        ...totalTestCases,
        totalTestCases: question_inputs.length,
      };
      // // console.log("totalTestCases:", updatedTotalTestCases);
      return updatedTotalTestCases;
    });

    // handleSubmit_final(question_inputs);

    await submitCode(code, runall);
  };

  const open_Console = () => {
    if (show_console) {
      setshow_console(false);
      setoutput_Data([]);
    } else {
      setshow_console(true);
    }
  };

  const pollApiForStatus = async (token) => {
    try {
      const response = await axios.get(
        "https://linkedlist-api.site/submissions/" +
        token +
        "?base64_encoded=true&fields=*"
      );
      const status = response.data.status.id;

      if (status > 2) {
        return response.data; // If status > 2, return the data
      } else {
        // If status is 1 or 2, poll every 2 seconds
        return new Promise((resolve) => {
          setTimeout(async () => {
            const data = await pollApiForStatus(token); // Recursively call pollApiForStatus to check again
            resolve(data);
          }, 2000); // Poll every 2 seconds
        });
      }
    } catch (err) {
      throw new Error(`Error fetching status for value: ${token}`);
    }
  };

  const updateCount = () => {
    setTestCasePassedCount(output_Data.length);
  };

  const getFunction = async (tokensList) => {
    // https://ce.judge0.com/submissions/d85cd024-1548-4165-96c7-7bc88673f194?base64_encoded=false&fields=stdout,stderr,status_id,language_id

    var tokens = tokensList.split(",");
    var GetTokensResponseDataList = [];
    for (let i = 0; i < tokens.length; i++) {
      // Fetch data for each value and wait for it to complete
      // // console.log("Processing for token : ", tokens[i]);
      const result = await pollApiForStatus(tokens[i]);
      // // console.log("Token  Result : ", result);
      GetTokensResponseDataList.push(result); // Store the result after status > 2
    }
    // // console.log("Get Response Data List : ", GetTokensResponseDataList);
    return GetTokensResponseDataList;
  };

  const fetch = require("node-fetch");
  const handleSubmit_final = async (question_inputs) => {
    // // console.log("iN hANDLE SUBMIT FINAL");
    // Judge0 API endpoint for running code
    showSuccessToast(`Compiled Successfully!`);
    const current_Errors = [];

    var data = [];
    // Iterate over each input and run the code with that input
    for (let i = 0; i < question_inputs.length; i++) {
      // Set up the data to send to the API
      data.push({
        language_id: language.id,
        // encode source code in base64
        source_code: btoa(code),
        stdin: btoa(question_inputs[i]),
        cpu_time_limit: language.runtime,
        memory_limit: 512000,
        cpu_extra_time: 0.0000001,
      });
    }
    // // console.log("Data : ", data);

    const apiCallForBatchSubmissions = {
      method: "POST",
      url: "https://linkedlist-api.site/submissions/batch",
      // withCredentials: true,
      params: {
        base64_encoded: "true",
      },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        submissions: data,
      },
    };

    try {
      const postSubmissionResponse = await axios.request(
        apiCallForBatchSubmissions
      );
      const postSubmissionResponseData = postSubmissionResponse.data;
      // // console.log("Post submission data : ", postSubmissionResponseData);

      var tokensList = "";
      for (let i = 0; i < postSubmissionResponseData.length; i++) {
        tokensList = tokensList + postSubmissionResponseData[i].token;
        if (i !== postSubmissionResponseData.length - 1) {
          tokensList = tokensList + ",";
        }
      }
      // // console.log("Token list : ", tokensList);
      var flag = true;
      var GetTokensResponseData = [];

      GetTokensResponseData = await getFunction(tokensList);
      let op_list = [];
      // // console.log("GEt function res : ", GetTokensResponseData);
      for (let i = 0; i < GetTokensResponseData.length; i++) {
        op_list.push(atob(GetTokensResponseData[i].stdout));
      }
      // // console.log("Expected o/p list : ", expected_outputs);
      // // console.log("Our o/p list : ", op_list);
      for (let i = 0; i < GetTokensResponseData.length; i++) {
        if (
          GetTokensResponseData[i].status.description === "Compilation Error" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGSEGV)" ||
          GetTokensResponseData[i].status.description ===
          "Time Limit Exceeded" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGXFSZ)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGFPE)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGABRT)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (NZEC)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (Other)"
        ) {
          // setProcessing(false);
          // setis_error(true);
          // setErrors({
          //   Input: question_inputs[i],
          //   description: GetTokensResponseData[i].status.description,
          //   Standard_Error: GetTokensResponseData[i].stderr
          //     ? String(atob(GetTokensResponseData[i].stderr))
          //     : "nullValue",
          // });
          // //// // console.log(String(atob(result_token.stderr)));
          // // setErrors(current_Errors);
          // setshow_output(true);
          // setRuntime_error(true);
          // settc_failed(true);
          setProcessing(processing => {
            const updatedProcessing = !processing;
            // // console.log("processing:", updatedProcessing);
            return updatedProcessing;
          });

          setis_error(true);
          // // console.log("is_error:", true);

          setErrors({
            Input: question_inputs[i],
            description: GetTokensResponseData[i].status.description,
            Standard_Error: GetTokensResponseData[i].stderr
              ? String(atob(GetTokensResponseData[i].stderr))
              : "nullValue",
          });
          // // console.log("Errors:", {
          //   Input: question_inputs[i],
          //   description: GetTokensResponseData[i].status.description,
          //   Standard_Error: GetTokensResponseData[i].stderr
          //     ? String(atob(GetTokensResponseData[i].stderr))
          //     : "nullValue",
          // });

          setshow_output(true);
          // // console.log("show_output:", true);

          settc_failed(false)
          // // console.log(tc_failed)

          setRuntime_error(true);
          // // console.log("Runtime_error:", true);
          if (loggedIn) {
            const statusRef = push(solvedStatusRef);
            set(statusRef, {
              solved: false,
              name: question.title,
              id: id,
            });
          }
          return;
        } else {
          // Decode the output and push it to the newOutputs array
          // // // console.log("Result token : ",atob(result_token.stdout))
          // newOutputs.push(result_token.stdout);
          // // console.log("Get Response STDOUT :", GetTokensResponseData[i].stdout);
          // // console.log(
          // "atob Get Response STDOUT :",
          //  atob(GetTokensResponseData[i].stdout)
          //);
          // // console.log("Expected output : ", expected_outputs[i]);

          if (
            String(atob(GetTokensResponseData[i].stdout)).replace("\n", "") !==
            String(expected_outputs[i]).replace("\n", "")
          ) {
            // // console.log("Error aa gayi retry");
            // setProcessing(prevProcessing => ({...prevProcessing, processing: false}));
            //   setProcessing(processing => !processing);
            //   settc_failed(tc_failed => !tc_failed);
            //   // settc_failed(prevtc => ({...prevtc , tc_failed : true}));

            //   setshow_output(show_output => !show_output);
            //   // setshow_output(show_output => ({...show_output, show_output: true}));
            //   setfailed_testcase({
            //     Input: question_inputs[i],
            //     User_Output: String(
            //       atob(GetTokensResponseData[i].stdout)
            //     ).replace("\n", ""),
            //     Expected_output: String(expected_outputs[i]).replace("\n", ""),
            //     Status: "Wrong Answer",
            //     Time: GetTokensResponseData[i].time,
            // });
            setProcessing((processing) => {
              const updatedProcessing = !processing;
              // // console.log("processing:", updatedProcessing);
              return updatedProcessing;
            });

            settc_failed((tc_failed) => {
              const updatedTcFailed = true;
              // // console.log("tc_failed:", updatedTcFailed);
              return updatedTcFailed;
            });

            // settc_failed(prevtc => ({...prevtc , tc_failed : true}));

            setshow_output((show_output) => {
              const updatedShowOutput = !show_output;
              // // console.log("show_output:", updatedShowOutput);
              return updatedShowOutput;
            });

            // setshow_output(show_output => ({...show_output, show_output: true}));

            setfailed_testcase({
              Input: question_inputs[i],
              User_Output: String(
                atob(GetTokensResponseData[i].stdout)
              ).replace("\n", ""),
              Expected_output: String(expected_outputs[i]).replace("\n", ""),
              Status: "Wrong Answer",
              Time: GetTokensResponseData[i].time,
            });
            // // console.log("failed_testcase:", {
            //   Input: question_inputs[i],
            //   User_Output: String(
            //     atob(GetTokensResponseData[i].stdout)
            //   ).replace("\n", ""),
            //   Expected_output: String(expected_outputs[i]).replace("\n", ""),
            //   Status: "Wrong Answer",
            //   Time: GetTokensResponseData[i].time,
            // });
            if (loggedIn) {
              const statusRef = push(solvedStatusRef);
              set(statusRef, {
                solved: false,
                name: question.title,
                id: id,
              });
            }
            return;
          } else {
            status = "Test Case Passed";
            // // console.log(status);
            output_Data.push({
              index: i,
              Inputs: input_arr[i],
              User_Output: String(
                atob(GetTokensResponseData[i].stdout)
              ).replace("\n", ""),
              Expec: expected_outputs[i],
              status: GetTokensResponseData[i].status.description,
              time: GetTokensResponseData[i].time,
              check_status: status,
              statusCode: GetTokensResponseData[i].status.id,
            });
            // setTestCasePassedCount(output_Data.length);
            updateCount();
            // // console.log("outputdata length");
            // // console.log(output_Data.length);
            // // console.log(testCasePassedCount);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    setcurr_status(true);
    setshow_output(true);
    setProcessing(false);
    //// // console.log("Show Output : ", show_output);
    //// // console.log(output_Data);
    if (output_Data.length === expected_outputs.length) {
      setcurr_status(true);
      setshow_output(true);
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: true,
          name: question.title,
          id: id,
        });
      }
    }
  };

  function handleThemeChange(th) {
    const theme = th;
    //// // console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
    // // console.log(theme);
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onClose = () => {
    setshow_output(false);
    setcurr_status(false);
    setoutput_Data([]);
    setis_error(false);
    setErrors([]);
    settc_failed(false);
    setTestCasePassedCount(0);
  };

  return (
    <>
      {/* { tc_failed ? 
    <>
      <p>{failed_testcase.Input.slice(0,20)}</p>
      <p>{failed_testcase.Status}</p>
      <p>{failed_testcase.User_Output}</p>
      <p>{failed_testcase.Expected_output}</p>
      <p>{failed_testcase.Time}</p>
      </>
      :
      <p>Tc failed False</p>
    } */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col min-w-[360px] h-[100vh]">
        <div className="h-[100vh]">
          <div class="flex h-full w-full flex-col">
            <Navbar />
            {isloading ? (
              <Spinner />
            ) : (
              <div className="flex flex-grow w-full overflow-y-hidden">
                <div class="flex max-md:flex-col h-full w-full dark:bg-dark-layer-bg">
                  <div
                    class="flex-1 overflow-auto w-[calc(23% - 4px)] ml-2 mr-1 my-2"
                    data-theme="halloween"
                  >
                    <div class="relative flex h-full flex-col">
                      <div className="mx-4">
                        <div class="overflow-auto mb-24" data-theme="halloween">
                          <center>
                            <u>
                              <h1 className="text-4xl">{question.title}</h1>
                              {/* <h2>{question.level}</h2> */}
                            </u>
                          </center>
                          <br></br>
                          {/* <> */}
                          <br></br>
                          <p
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                            className="text-xl max-md:text-lg max-sm:text-sm"
                          >
                            {question.description}
                          </p>
                          <br></br>
                          {/* <p>Input - {sample_input}</p> */}
                          <div
                            className="card w-66 bg-base-100 shadow-xl"
                            data-theme="forest"
                          >
                            <div className="card-body">
                              <b className="text-xl max-md:text-lg max-sm:text-sm">Sample Input : </b>
                              <div className="text-xl max-md:text-lg max-sm:text-sm">{sample_input &&
                                sample_input
                                  .split("\n")
                                  .map((si) => <p> {si.replace('"', "")} </p>)}
                              </div>
                            </div>
                          </div>
                          <br></br>
                          <div
                            className="card w-66 bg-base-100 shadow-xl"
                            data-theme="forest"
                          >
                            <div className="card-body">
                              <b className="text-xl max-md:text-lg max-sm:text-sm">Sample Output : </b>
                              <div className="text-xl max-md:text-lg max-sm:text-sm">{sample_output}</div>
                            </div>
                          </div>
                          <br></br>
                          <div
                            className="card w-66 bg-base-100 shadow-xl"
                            data-theme="forest"
                          >
                            <div className="card-body">
                              <b className="text-xl max-md:text-lg max-sm:text-sm">Constraints : </b>

                              <div className="text-xl max-md:text-lg max-sm:text-sm">{question.constraints &&
                                question.constraints.split("\n").map((co) => (
                                  <p>
                                    {co.replaceAll("$", "").split("^")[0]}
                                    <sup>
                                      {co.replaceAll("$", "").split("^")[1]}
                                    </sup>
                                  </p>
                                ))}</div>
                            </div>
                          </div>
                          {/* </> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <>
                    {loggedIn ? (
                      <div
                        class="flex-1 flex-col w-[calc(54% - 8px)] mx-2 my-2"
                        data-theme="halloween"
                      >
                        <div class="relative flex h-full flex-col">
                          <div class="flex-grow min-h-0">
                            <div class="flex h-full flex-col">
                              <div class="flex h-full flex-col">
                                <div class="relative flex h-full flex-col overflow-hidden rounded">
                                  <div class="flex h-9 items-center rounded-t pr-4">
                                    <div class="mr-auto flex flex-nowrap items-center gap-3">
                                      <div className="ml-12 text-black">
                                        <LanguagesDropdown
                                          onSelectChange={onSelectChange}
                                        />
                                      </div>
                                      {/* <div className="px-4 py-2">
                                          <ThemeDropdown
                                            handleThemeChange={handleThemeChange}
                                            theme={theme}
                                          />
                                        </div> */}
                                    </div>
                                  </div>

                                  {/* <CodeEditorWindow
                                        code={code}
                                        onChange={onChange}
                                        language={language?.value}
                                        theme={theme.value}
                                      /> */}
                                  <div class="flex flex-1 flex-col overflow-hidden mt-2">
                                    <div class="flex-1 overflow-hidden">
                                      <div className="h-full w-full">
                                        {show_console === false ? (
                                          <>
                                            <AceEditor
                                              mode={editor_mode}
                                              theme="terminal"
                                              onChange={onChange}
                                              name="UNIQUE_ID_OF_DIV"
                                              value={code}
                                              width="100%"
                                              height="100%"
                                              fontSize={16}
                                              placeholder=""
                                              editorProps={{
                                                $blockScrolling: true,
                                              }}
                                              setOptions={{
                                                enableBasicAutocompletion: true,
                                                enableLiveAutocompletion: true,
                                                enableSnippets: true,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <AceEditor
                                              mode={editor_mode}
                                              theme="terminal"
                                              onChange={onChange}
                                              name="UNIQUE_ID_OF_DIV"
                                              value={code}
                                              width="100%"
                                              height="50%"
                                              fontSize={16}
                                              placeholder=""
                                              editorProps={{
                                                $blockScrolling: true,
                                              }}
                                              setOptions={{
                                                enableBasicAutocompletion: true,
                                                enableLiveAutocompletion: true,
                                                enableSnippets: true,
                                              }}
                                            />
                                          </>
                                        )}
                                        {show_console && (
                                          <div
                                            class="overflow-auto"
                                            data-theme="forest"
                                          >
                                            <div className="card w-full h-80 bg-base-100 shadow-xl animate-fadeIn">
                                              <div className="card-body">
                                                {processing ? (
                                                  <>
                                                    <center>
                                                      <h2 className="card-title ">
                                                        Processing Test Cases...
                                                      </h2>
                                                    </center>
                                                    <RoomSpinner />
                                                  </>
                                                ) : !processing &&
                                                  curr_status ? (
                                                  runAllVar ? <>
                                                    <center>
                                                      <h2 className="card-title">
                                                        Processing Test Cases...
                                                      </h2>
                                                    </center>
                                                    <br></br>
                                                    <div className="alert alert-success  animate-bounce">
                                                      <center>
                                                        {" "}
                                                        <span>
                                                          ✅ Yayy, All Test
                                                          cases Passed!
                                                        </span>
                                                      </center>
                                                    </div>
                                                  </> : <>
                                                    <div>
                                                      <div className="alert alert-success  animate-bounce">
                                                        <center>
                                                          {" "}
                                                          <span>
                                                            ✅ Yayy, Test Case
                                                            Passed!
                                                          </span>
                                                        </center>
                                                      </div>
                                                      <br></br>
                                                      <h1>Input : </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        {sample_input.split(
                                                          "\n"
                                                        ).map((si) => (
                                                          <p>
                                                            {" "}
                                                            {si.replace(
                                                              '"',
                                                              ""
                                                            )}{" "}
                                                          </p>
                                                        ))}
                                                      </div>
                                                      <h1>Expected Output: </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        <span>
                                                          {
                                                            sample_output
                                                          }
                                                        </span>
                                                      </div>
                                                      <h1>Your Output : </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        <span>
                                                          {
                                                            sample_output
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : tc_failed ? (
                                                  <div>
                                                    <h1>Test Case Failed!</h1>
                                                    <br></br>
                                                    <h1>Input : </h1>
                                                    <div
                                                      className="alert"
                                                      data-theme="halloween"
                                                    >
                                                      {failed_testcase.Input.split(
                                                        "\n"
                                                      ).map((si) => (
                                                        <p>
                                                          {" "}
                                                          {si.replace(
                                                            '"',
                                                            ""
                                                          )}{" "}
                                                        </p>
                                                      ))}
                                                    </div>
                                                    <h1>Expected Output: </h1>
                                                    <div
                                                      className="alert"
                                                      data-theme="halloween"
                                                    >
                                                      <span>
                                                        {
                                                          failed_testcase.Expected_output
                                                        }
                                                      </span>
                                                    </div>
                                                    <h1>Your Output : </h1>
                                                    <div
                                                      className="alert"
                                                      data-theme="halloween"
                                                    >
                                                      <span>
                                                        {
                                                          failed_testcase.User_Output
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : runtime_error ? (
                                                  <div>
                                                    <h1>
                                                      Oops! Some error occured!
                                                    </h1>
                                                    <h1>Input :</h1>
                                                    <div
                                                      className="alert"
                                                      data-theme="halloween"
                                                    >
                                                      <span>
                                                        {errors.Input.slice(
                                                          0,
                                                          20
                                                        ) + "....."}
                                                      </span>
                                                    </div>
                                                    <br></br>
                                                    <h1>Error :</h1>
                                                    <div
                                                      className="alert"
                                                      data-theme="halloween"
                                                    >
                                                      <span>
                                                        {errors.Standard_Error ===
                                                          "nullValue"
                                                          ? errors.description
                                                          : errors.Standard_Error}
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <center>
                                                      <h2 className="text-xl max-md:text-lg max-sm:text-sm">
                                                        You have not submitted
                                                        any code.
                                                      </h2>
                                                    </center>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex items-end">
                            <div className="flex flex-1 flex-nowrap items-center gap-4 m-2">
                              <button
                                className="btn text-md max-sm:text-[12px]"
                                data-theme="light"
                                onClick={open_Console}
                              >
                                Console
                              </button>
                            </div>
                            <div className="relative ml-auto flex items-center gap-3 m-2">
                              <button
                                onClick={() => compile(code, false)}
                                class="btn relative flex flex-row-reverse text-md max-sm:text-[12px]"
                                data-theme="light"
                              >
                                {processing && !runAllVar ? "Processing..." : "Run"}
                              </button>
                              <button
                                onClick={() => compile(code, true)}
                                class="btn relative flex flex-row-reverse text-md max-sm:text-[12px]"
                                data-theme="light"
                              >
                                {processing && runAllVar ? "Processing..." : "Submit"}
                              </button>
                            </div>
                          </div>
                          {/* <button
                              onClick={() => compile(code)}
                              disabled={!code}
                              className={classnames(
                                "mt-4 border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                                !code ? "opacity-50" : ""
                              )}
                            >
                              {processing ? "Processing..." : "Submit"}
                            </button> */}
                        </div>
                      </div>
                    ) : (
                      <div class="hero min-h-screen bg-base-200">
                        <div class="hero-content text-center">
                          <div class="max-w-md">
                            <h1 class="text-4xl font-bold">
                              Please Login/Sign Up to solve this problem.
                            </h1>
                            <br></br>
                            <Link to="/sign-in">
                              <button class="btn btn-secondary">LogIn</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  {/* )} */}
                  {/* </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Landing;
