/*eslint-env es6*/
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  getDatabase,
  ref,
  set,
  push,
  get,
  child,
  remove,
} from "firebase/database";
import { database } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { useState, useEffect, useRef } from "react";
import { onValue } from "firebase/database";
import data from "../data/data.json";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import axios from "axios";
// import Roomques from "../pages/Roomques";
import Timer from "./Timer";
import RoomSpinner from "./RoomSpinner";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import RoomCodeEditor from "./RoomCodeEditor";
import LanguagesDropdown from "../components/LanguagesDropdown";
import Messages from "./Messages";
import AceEditor from "react-ace";
import { languageOptions } from "../constants/languageOptions";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/ext-language_tools";
import "animate.css";
import Submissions from "./Submissions";
import Navbar from "./Navbar/Navbar";
import { useAuthStatus } from "../hooks/useAuthStatus";
import RoomLeaderboard from "./RoomLeaderboard";
import Spinner from "./Spinner";
import CopyLinkButton from "./CopyLinkButton";


const javascriptDefault = ``;
const RoomForm = () => {
  const location = useLocation();
  const auth = getAuth();
  const database = getDatabase();
  const { state } = location || {};
  const [processing, setProcessing] = useState(false);
  const [room_questions, setroom_questions] = useState([]);
  const [room_name, setroom_name] = useState("");
  const [room_users, setroom_users] = useState([]);
  const [all_messages, setAll_messages] = useState([]);
  const [isroom_user, setisroom_user] = useState(false);
  const [data, setdata] = useState([]);
  const [show_ques, setshow_ques] = useState(false);
  const [current_ques, setcurrent_ques] = useState({});
  const [prev_ques, setprev_ques] = useState("");
  const [timer_started, settimer_started] = useState(false);
  const [q, setq] = useState([]);
  const [show_problems, setshow_problems] = useState(false);
  const [show_submissions, setshow_submissions] = useState(false);
  const [show_discussions, setshow_discussions] = useState(false);
  const [all, setall] = useState(false);
  const [language, setLanguage] = useState(languageOptions[0]);
  const [editor_mode, seteditor_mode] = useState("c_cpp");
  const [code, setCode] = useState(javascriptDefault);
  const [minutes, setminutes] = useState(0);
  const [seconds, setseconds] = useState(0);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const navigate = useNavigate();

  const [output_Data, setoutput_Data] = useState([]);
  const [curr_status, setcurr_status] = useState(false);
  const [show_output, setshow_output] = useState(false);
  const [question, setquestion] = useState({});
  const [question_inputs, setquestion_inputs] = useState([]);
  const [question_outputs, setquestion_outputs] = useState([]);
  const [ques_loaded, setques_loaded] = useState(false);
  const [is_error, setis_error] = useState(false);
  const [error_tc, seterror_tc] = useState({});
  const [tc_failed, settc_failed] = useState(false);
  const [failed_testcase, setfailed_testcase] = useState({});
  const [outputs, setOutputs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [show_output_arr, setshow_output_arr] = useState(false);
  const [testCasePassedCount, setTestCasePassedCount] = useState(0);
  const [totalTestCases, setTotalTestCases] = useState(0);
  const [runtime_error, setRuntime_error] = useState(false);
  const [error, seterror] = useState("");
  // const { from } = location.state;
  const [show_console, setshow_console] = useState(false);
  const [ques1_code, setques1_code] = useState("");
  const [ques2_code, setques2_code] = useState("");
  const [ques3_code, setques3_code] = useState("");
  const [ques4_code, setques4_code] = useState("");
  const [showisprocessing_modal, setshowisprocessing_modal] = useState(false);
  const [all_users_ready, setall_users_ready] = useState(false);
  const [length_of_all_users_list, setlength_of_all_users_list] = useState(0);
  const [show_ready_btn, setshow_ready_btn] = useState(true);
  const [is_user_Ready, setis_user_Ready] = useState(false);
  const [already_started, setalready_started] = useState(false);
  const [show_leaderboard, setshow_leaderboard] = useState(false);
  const [leaderboard_list, setleaderboard_list] = useState([]);
  const [room_timer_completed, setRoom_timer_completed] = useState(false)
  const [runAllVar, setrunAllVar] = useState(false)
  const { loggedIn, checkingStatus } = useAuthStatus();

  const from = useMemo(() => {
    if (state?.from) {
      return state.from;
    }

    const pathParts = location.pathname.split("/");
    return pathParts[pathParts.length - 1];
  }, [location.pathname])

  let solvedStatusRef = "";
  if (loggedIn) {
    solvedStatusRef = ref(
      database,
      "rooms/" + from + "/submission/" + auth.currentUser.uid
    );
  }

  useEffect(() => {
      // const roomRef = ref(database, "rooms/" + from + "/questions");

    const roomuserRef = ref(database, "rooms/" + from + "/users");

    onValue(roomuserRef, (snapshot) => {
      const data = snapshot.val();
      let users = [];
      for (let d in data) {
        if (data[d].name) {
          users.push(
            //data[d].email
            data[d].name
          );
        }
        else {
          users.push(
            data[d].email
          )
        }
        if (loggedIn && data[d].uuid === auth.currentUser.uid) {
          setisroom_user(true);
        }
        
      }

      
        const timerRef = ref(database, "rooms/" + from + "/timer");
        onValue(timerRef, (snapshot) => {
          const roomData = snapshot.val();
          if (
            roomData.timer_started === true
          ) {
            setalready_started(true);
          }
          if(roomData.timer_completed === true){
            setRoom_timer_completed(true);
          }
        });
      

      const nameRef = ref(database, "rooms/" + from + "/name");
      onValue(nameRef, (snapshot) => {
        const roomData = snapshot.val();
        setroom_name(roomData.name);
      });

      const apiquesRef = ref(database, "rooms/" + from + "/questions-api");
      onValue(apiquesRef, (snapshot) => {
        const data = snapshot.val();
        let questns = [];
        for (let d in data) {
          for (let i = 0; i < 4; i++) {
            questns.push({
              id: data[d][i].question_id,
              title: data[d][i].title,
              constraints: data[d][i].constraints,
              description: data[d][i].description,
              level: data[d][i].level,
              sample_input: data[d][i].sample_input,
              sample_output: data[d][i].sample_output,
              space_complexity: data[d][i].space_complexity,
              time_complexity: data[d][i].time_complexity,
            });
          }
        }

        // const apiquesRef = ref(database, "rooms/" + from + "/questions-api");

        setroom_questions(questns);
      });



      // // console.log("users: ", users)
      setroom_users(users);
      // check_all_users_ready()
      setlength_of_all_users_list(users.length);
    });

    // // console.log("room_users: ", room_users)
    // // console.log("length_of_all_users_list in useEffect: ", length_of_all_users_list)

    const all_messages = ref(database, "rooms/" + from + "/messages");
    onValue(all_messages, (snapshot) => {
      const data = snapshot.val();
      let messages = [];
      for (let d in data) {
        messages.push({
          message: data[d].message,
          user: data[d].user,
        });
      }
      setAll_messages(messages);
      // let objDiv = document.getElementById("msg");
      // objDiv.scrollTop = objDiv.scrollHeight;
    });

    if (room_questions.length > 0) {
      setall(true);
    }

    const submission_ref = ref(database, "rooms/" + from + "/submission/");
    onValue(submission_ref, (snapshot) => {
      var leaderboard = [];
      const data = snapshot.val();
      for (let d in data) {
        var users_points = 0;
        var time_q1 = "";
        var time_q2 = "";
        var time_q3 = "";
        var time_q4 = "";
        const solvedStatusRef2 = ref(
          database,
          "rooms/" + from + "/submission/" + String(d)
        );
        var username = "";
        onValue(solvedStatusRef2, (snapshot) => {
          const submissiondata = snapshot.val();
          for (let d in submissiondata) {
            users_points += submissiondata[d].points;
            username = submissiondata[d].username;
            if (room_questions[0].id === submissiondata[d].id) {
              time_q1 = submissiondata[d].time;
            } else if (room_questions[1].id === submissiondata[d].id) {
              time_q2 = submissiondata[d].time;
            } else if (room_questions[2].id === submissiondata[d].id) {
              time_q3 = submissiondata[d].time;
            } else if (room_questions[3].id === submissiondata[d].id) {
              time_q4 = submissiondata[d].time;
            }
          }
          leaderboard.push({
            username: username,
            q1: time_q1,
            q2: time_q2,
            q3: time_q3,
            q4: time_q4,
            points: users_points,
          });
        });
      }
      setleaderboard_list(leaderboard);

    });

    if ((all_users_ready === true || already_started === true) && isroom_user !== false) {
      showproblems(0);
    }

    let objDiv = document.getElementById("msg");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [loggedIn]);

  // const setques = (id) => {
  //   setcurrent_ques(id);
  //   setshow_ques(true);
  // };

  const [formData, setFormData] = useState({
    message: "",
  });
  const { message } = formData;
  const onChange2 = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const room_already_started = async () => {
    try {
      const dataRef = ref(database, "rooms/" + from + "/timer");
      const snapshot = await get(dataRef);
        const roomTimerData = snapshot.val();
        // // console.log(roomTimerData)
        if (
          roomTimerData.timer_started === true
        ) {
          setalready_started(true);
        }
      } catch {

      }
      return;
  }

  const joinroom = async (e) => {
    await room_already_started()
    const userData = auth.currentUser.uid;
    const email = auth.currentUser.email;
    const roomuserRef = ref(database, "rooms/" + from + "/users");
    const newroomusersRef = push(roomuserRef);
    set(newroomusersRef, {
      uuid: userData,
      email: email,
      name: auth.currentUser.displayName,
    });
    setisroom_user(true);
    // setroom_users((prevUsers) => [...prevUsers, auth.currentUser.displayName])
    // showproblems()
  };

  // // console.log("new room users: ", room_users)

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.message.length > 0) {
      try {
        const formDataCopy = { ...formData };
        formDataCopy.user = auth.currentUser.displayName;

        const postListRef = ref(database, "rooms/" + from + "/messages");
        const newPostRef = push(postListRef);
        set(newPostRef, formDataCopy);

        let objDiv = document.getElementById("msg");
        objDiv.scrollTop = objDiv.scrollHeight;
        setFormData({ message: "" });

        //navigate('/')
      } catch (error) {
        toast.info("Please type a message");
      }
    } else {
      toast.info("Please type a message");
    }
  };

  const leaveRoom = async (e) => {
    const userData = auth.currentUser.uid;
    const roomuserRef = ref(database, "rooms/" + from + "/users");

    get(roomuserRef)
      .then((snapshot) => {
        const data = snapshot.val();
        for (let d in data) {
          if (data[d].uuid === userData) {
            const p = ref(database, "rooms/" + from + "/users/" + d);
            remove(p).then(() => {
              setisroom_user(false);
              setcurrent_ques({})
            });
          }
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const onChangeques = (ques_id) => {
    if (processing === true) {
      // setshowisprocessing_modal(true)
      toast.error(
        `You can't switch between problems, while executing your code.`,
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      setcurrent_ques(room_questions[ques_id]);
      setoutput_Data([]);
      setshow_console(false);
    }
  };

  const showproblems = async () => {
    setshow_problems(true);
    onChangeques(0);
  };

  const showsubmissions = (ques_id) => {
    setshow_problems(false);
    setshow_submissions(true);
  };

  const showdiscussions = (ques_id) => {
    setshow_problems(false);
    setshow_discussions(ques_id);
  };

  const onSelectChange = (sl) => {
    setLanguage(sl);
    if (sl.id === 54) {
      seteditor_mode("c_cpp");
    } else if (sl.id === 62) {
      seteditor_mode("java");
    } else {
      seteditor_mode("python");
    }
  };

  const onChange = (data) => {
    if (current_ques === room_questions[0]) {
      setques1_code(data);
    } else if (current_ques === room_questions[1]) {
      setques2_code(data);
    } else if (current_ques === room_questions[2]) {
      setques3_code(data);
    } else if (current_ques === room_questions[3]) {
      setques4_code(data);
    }
  };

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const pollApiForStatus = async (token) => {
    try {
      const response = await axios.get(
        "https://linkedlist-api.site/submissions/" +
        token +
        "?base64_encoded=true&fields=*"
      );
      const status = response.data.status.id;

      if (status > 2) {
        return response.data; // If status > 2, return the data
      } else {
        // If status is 1 or 2, poll every 2 seconds
        return new Promise((resolve) => {
          setTimeout(async () => {
            const data = await pollApiForStatus(token); // Recursively call pollApiForStatus to check again
            resolve(data);
          }, 2000); // Poll every 2 seconds
        });
      }
    } catch (err) {
      throw new Error(`Error fetching status for value: ${token}`);
    }
  };

  const getFunction = async (tokensList) => {
    // https://ce.judge0.com/submissions/d85cd024-1548-4165-96c7-7bc88673f194?base64_encoded=false&fields=stdout,stderr,status_id,language_id

    var tokens = tokensList.split(",");
    var GetTokensResponseDataList = [];
    for (let i = 0; i < tokens.length; i++) {
      // Fetch data for each value and wait for it to complete
      const result = await pollApiForStatus(tokens[i]);
      GetTokensResponseDataList.push(result); // Store the result after status > 2
    }
    return GetTokensResponseDataList;
  };

  const fetch = require("node-fetch");
  const handleSubmit_final = async (
    code,
    question_inputs,
    expected_outputs
  ) => {
    // Judge0 API endpoint for running code
    // showSuccessToast(`Compiled Successfully!`);
    const current_Errors = [];

    var data = [];
    var language_id = 0;
    var language_runtime = 0;
    if (editor_mode === "c_cpp") {
      language_id = 54;
      language_runtime = 1.0;
    } else if (editor_mode === "java") {
      language_id = 62;
      language_runtime = 2.0;
    } else if (editor_mode === "python") {
      language_id = 71;
      language_runtime = 5.0;
    }
    // Iterate over each input and run the code with that input
    for (let i = 0; i < question_inputs.length; i++) {
      // Set up the data to send to the API
      data.push({
        language_id: language_id,
        // encode source code in base64
        source_code: btoa(code),
        stdin: btoa(question_inputs[i]),
        cpu_time_limit: language_runtime,
        memory_limit: 512000,
        cpu_extra_time: 0.0000001,
      });
    }


    const apiCallForBatchSubmissions = {
      method: "POST",
      url: "https://linkedlist-api.site/submissions/batch",
      params: {
        base64_encoded: "true",
      },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        submissions: data,
      },
    };

    try {
      const postSubmissionResponse = await axios.request(
        apiCallForBatchSubmissions
      );
      const postSubmissionResponseData = postSubmissionResponse.data;

      var tokensList = "";
      for (let i = 0; i < postSubmissionResponseData.length; i++) {
        tokensList = tokensList + postSubmissionResponseData[i].token;
        if (i !== postSubmissionResponseData.length - 1) {
          tokensList = tokensList + ",";
        }
      }
      var flag = true;
      var GetTokensResponseData = [];

      GetTokensResponseData = await getFunction(tokensList);

      for (let i = 0; i < GetTokensResponseData.length; i++) {
        if (
          GetTokensResponseData[i].status.description === "Compilation Error" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGSEGV)" ||
          GetTokensResponseData[i].status.description ===
          "Time Limit Exceeded" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGXFSZ)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGFPE)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (SIGABRT)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (NZEC)" ||
          GetTokensResponseData[i].status.description ===
          "Runtime Error (Other)"
        ) {
          setProcessing(false);
          setis_error(true);
          setErrors({
            Input: question_inputs[i],
            description: GetTokensResponseData[i].status.description,
            Standard_Error: GetTokensResponseData[i].stderr
              ? String(atob(GetTokensResponseData[i].stderr))
              : "nullValue",
          });
          // setErrors(current_Errors);
          setshow_output(true);
          setRuntime_error(true);
          // settc_failed(true);
          if (loggedIn) {
            const statusRef = push(solvedStatusRef);
            set(statusRef, {
              solved: false,
              name: current_ques.title,
              id: current_ques.question_id,
            });
          }
          return;
        } else {
          // Decode the output and push it to the newOutputs array
          // // console.log("Result token : ",atob(result_token.stdout))
          // newOutputs.push(result_token.stdout);
          // // console.log("User o/p = ", String(atob(GetTokensResponseData[i].stdout)).replace("\n", ""))
          // // console.log("Expected O/p : ", String(expected_outputs[i]).replace("\n", ""))
          if (
            String(atob(GetTokensResponseData[i].stdout)).replace("\n", "") !==
            String(expected_outputs[i]).replace("\n", "")
          ) {
            setProcessing(false);
            settc_failed(true);
            setshow_output(true);
            setfailed_testcase({
              Input: question_inputs[i],
              User_Output: String(
                atob(GetTokensResponseData[i].stdout)
              ).replace("\n", ""),
              Expected_output: String(expected_outputs[i]).replace("\n", ""),
              Status: "Wrong Answer",
              Time: GetTokensResponseData[i].time,
            });
            if (loggedIn) {
              const statusRef = push(solvedStatusRef);
              set(statusRef, {
                solved: false,
                name: current_ques.title,
                id: current_ques.current_ques_id,
              });
              return;
            }
          } else {
            var statuss = "";
            statuss = "Test Case Passed";
            output_Data.push({
              index: i,
              Inputs: question_inputs[i],
              User_Output: String(
                atob(GetTokensResponseData[i].stdout)
              ).replace("\n", ""),
              Expec: expected_outputs[i],
              status: GetTokensResponseData[i].status.description,
              time: GetTokensResponseData[i].time,
              check_status: statuss,
              statusCode: GetTokensResponseData[i].status.id,
            });
            setTestCasePassedCount(output_Data.length);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    if (output_Data.length === expected_outputs.length) {
      setcurr_status(true);
      setshow_output(true);
      setProcessing(false);
      const timerRef = ref(database, "rooms/" + from + "/timer");
      var minutes_var = 0;
      var seconds_var = 0;
      onValue(timerRef, (snapshot) => {
        const roomData = snapshot.val();
        if (roomData.timer_completed === false) {
          const { startTime } = roomData;
          const elapsedTime = Date.now() - startTime;
          minutes_var = Math.floor(elapsedTime / 60000);
          seconds_var = ((elapsedTime % 60000) / 1000).toFixed(0);
          setminutes(minutes_var);
          setseconds(seconds_var);
        }
      });

      var already_submitted = false;
      onValue(solvedStatusRef, (snapshot) => {
        const data = snapshot.val();
        for (let d in data) {
          if (data[d].id === current_ques.id) {
            already_submitted = true;
          }
        }
      });
      if (already_submitted === false) {
        var ques = 0;

        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: true,
          title: current_ques.title,
          id: current_ques.id,
          points: 100,
          time: minutes_var + ":" + seconds_var,
          username: auth.currentUser.displayName,
        });
      }

      // const roomsolvedStatusRef = ref(
      //   database,
      //   "rooms/" + from + "/submission/" + auth.currentUser.uid
      // );
    }
  };

  const onClose = () => {
    setshow_output(false);
    setcurr_status(false);
    setoutput_Data([]);
    setis_error(false);
    setErrors([]);
    settc_failed(false);
    setTestCasePassedCount(0);
  };

  const submitCode = async (code, runall) => {
    var language_id = language.id;
    var language_runtime = language.runtime;
    const data = {
      quesId: current_ques.id,
      decodedCode: btoa(code),
      languageId: language_id,
      languageRuntime: language_runtime,
      runall: runall
    }
    // console.log("Code : ", btoa(code))

    const res = await axios.post('https://ed-tech-backend-eight.vercel.app/submitCode/', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log("res ", res)
    let status = res["data"]["status"];
    // console.log("status : ", status)
    if (status === "ALL PASSED") {
      // console.log("ALL PASSED")
      status = "Test Case Passed";
      setcurr_status(true);
      setshow_output(true);
      setProcessing(false);
      //// // console.log("Show Output : ", show_output);
      //// // console.log(output_Data);
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: true,
          name: current_ques.title,
          id: current_ques.id,
        });
      }
      setcurr_status(true);
      setshow_output(true);
      setProcessing(false);
      const timerRef = ref(database, "rooms/" + from + "/timer");
      var minutes_var = 0;
      var seconds_var = 0;
      onValue(timerRef, (snapshot) => {
        const roomData = snapshot.val();
        if (roomData.timer_completed === false) {
          const { startTime } = roomData;
          const elapsedTime = Date.now() - startTime;
          minutes_var = Math.floor(elapsedTime / 60000);
          seconds_var = ((elapsedTime % 60000) / 1000).toFixed(0);
          setminutes(minutes_var);
          setseconds(seconds_var);
        }
      });

      var already_submitted = false;
      onValue(solvedStatusRef, (snapshot) => {
        const data = snapshot.val();
        for (let d in data) {
          if (data[d].id === current_ques.id) {
            already_submitted = true;
          }
        }
      });
      if (already_submitted === false) {
        var ques = 0;

        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: true,
          title: current_ques.title,
          id: current_ques.id,
          points: 100,
          time: minutes_var + ":" + seconds_var,
          username: auth.currentUser.displayName,
        });
      }
      // updateCount();
      return
    } else if (status === "WRONG ANSWER") {
      const [input, userOutput, expectedOutput] = res["data"]["failedTestCase"];

      // let input = res["failedTestCase"][0]
      // let userOutput = res["failedTestCase"][1]
      // let expectedOutput = res["failedTestCase"][2]

      setProcessing((processing) => {
        const updatedProcessing = !processing;
        // // console.log("processing:", updatedProcessing);
        return updatedProcessing;
      });

      settc_failed((tc_failed) => {
        const updatedTcFailed = true;
        // // console.log("tc_failed:", updatedTcFailed);
        return updatedTcFailed;
      });

      // settc_failed(prevtc => ({...prevtc , tc_failed : true}));

      setshow_output((show_output) => {
        const updatedShowOutput = !show_output;
        // // console.log("show_output:", updatedShowOutput);
        return updatedShowOutput;
      });

      // setshow_output(show_output => ({...show_output, show_output: true}));

      setfailed_testcase({
        Input: input,
        User_Output: userOutput.replace("\n", ""),
        Expected_output: expectedOutput,
        Status: "Wrong Answer",
      });
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: false,
          name: current_ques.title,
          id: current_ques.id,
        });
      }
      return;
    } else {
      setProcessing(processing => {
        const updatedProcessing = !processing;
        // // console.log("processing:", updatedProcessing);
        return updatedProcessing;
      });

      setis_error(true);
      // // console.log("is_error:", true);
      // // console.log("faied : ", res["failedTestCase"])
      const [input, output, expected] = res["data"]["failedTestCase"];
      setErrors({
        Input: input,
        description: res["data"]["error"],
        Standard_Error: res["data"]["stderr"]
          ? res["data"]["stderr"]
          : "nullValue",
      });
      setshow_output(true);
      // // console.log("show_output:", true);

      settc_failed(false)
      // // console.log(tc_failed)

      setRuntime_error(true);
      // // console.log("Runtime_error:", true);
      if (loggedIn) {
        const statusRef = push(solvedStatusRef);
        set(statusRef, {
          solved: false,
          name: current_ques.title,
          id: current_ques.id,
        });
      }
      return;
    }
    return;
  }

  const compile = async (code, runall) => {
    if (code === "") {
      showErrorToast("Please Add some code before submitting!");
      return;
    }

    setrunAllVar(runall)

    setProcessing((prevProcessing) => {
      const updatedProcessing = { ...prevProcessing, processing: true };
      // // console.log("processing:", updatedProcessing);
      return updatedProcessing;
    });

    setshow_console(true);
    // // console.log("show_console:", show_console);

    setshow_output(false);
    // // console.log("show_output:", show_output);

    setcurr_status(false)
    // // console.log("Current StatuS : ", curr_status)

    setRuntime_error(false)
    // // console.log("runtime error = ", runtime_error)

    setfailed_testcase({})
    // // console.log("failed test case :", failed_testcase)

    seterror("");
    // // console.log("error:", "");

    setTotalTestCases((totalTestCases) => {
      const updatedTotalTestCases = {
        ...totalTestCases,
        totalTestCases: question_inputs.length,
      };
      // // console.log("totalTestCases:", updatedTotalTestCases);
      return updatedTotalTestCases;
    });

    // handleSubmit_final(question_inputs);

    await submitCode(code, runall);
  };

  const compile1 = async (code, runall) => {
    if (code === "") {
      showErrorToast("Please Add some code before submitting!");
      return;
    }

    var language_id = 0;
    var language_runtime = 0;
    if (editor_mode === "c_cpp") {
      language_id = 54;
      language_runtime = 1.0;
    } else if (editor_mode === "java") {
      language_id = 62;
      language_runtime = 2.0;
    } else if (editor_mode === "python") {
      language_id = 71;
      language_runtime = 5.0;
    }
    const data =  {quesId : current_ques.id,
      code : code,
      languageId : language_id,
      languageRuntime : language_runtime
    } 
    // console.log("Code : ", code)

    const res = await axios.post('https://ed-tech-backend-eight.vercel.app/submitCode/', data, {
      headers: {
          'Content-Type': 'application/json',
      },
  });
    return;

    settc_failed(false);
    setcurr_status(false);
    setshow_console(true);
    setTestCasePassedCount(0);
    setProcessing(true);
    setshow_output(false);
    seterror("");
    setoutput_Data([]);
    setRuntime_error(false);
    setTotalTestCases(question_inputs.length);

    var question_input_list = [];
    var question_expected_output_list = [];

    await axios
      .get(
        "https://ed-tech-backend-eight.vercel.app/problems/" + current_ques.id,
        {
          mode: "no-cors",
        }
      )
      .then((response) => {
        //setquestion(response.data);
        //setquestion_inputs(response.data.input_list);
        const data = JSON.parse(response.data.input_list);
        question_input_list = data;

        const data2 = JSON.parse(response.data.output_list);
        question_expected_output_list = data2;
        handleSubmit_final(
          code,
          question_input_list,
          question_expected_output_list
        );
      });

    // if (current_ques === room_questions[0]) {
    //   question_input_list = room_questions[0].input_list
    //   question_expected_output_list = room_questions[0].output_list
    // }else if (current_ques === room_questions[1]) {
    //   question_input_list = room_questions[1].input_list
    //   question_expected_output_list = room_questions[1].output_list
    // }else if (current_ques === room_questions[2]) {
    //   question_input_list = room_questions[2].input_list
    //   question_expected_output_list = room_questions[2].output_list
    // }else if (current_ques === room_questions[3]) {
    //   question_input_list = room_questions[3].input_list
    //   question_expected_output_list = room_questions[3].output_list
    // }
  };

  const open_Console = () => {
    if (show_console) {
      setshow_console(false);
      setoutput_Data([]);
    } else {
      setshow_console(true);
    }
  };

  const check_all_users_ready = () => {
    const user_ready_Ref = ref(database, "rooms/" + from + "/user_ready");
    onValue(user_ready_Ref, (snapshot) => {
      const data = snapshot.val();
      let users = [];
      let count_of_ready_users = 0;
      for (let d in data) {
        if (data[d].uuid === auth.currentUser.uid) {
          setis_user_Ready(true);
        }
        count_of_ready_users += 1;
        // // console.log("ready users data: ", data)
        // // console.log("count_of_ready_users: ", count_of_ready_users)
        // // console.log("room_users: ", room_users)
        // // console.log("length_of_all_users_list: ", length_of_all_users_list)
        if (length_of_all_users_list === count_of_ready_users) {
          setall_users_ready(true);
          // // console.log("all_users_ready: ", all_users_ready)
          const liveroomRef = ref(database, "rooms/" + from + "/live/");
          set(liveroomRef, { is_live: true });
          showproblems();
        } else {
          const liveroomRef = ref(database, "rooms/" + from + "/live/");
          set(liveroomRef, { is_live: false });
        }
      }
    });
  };

  // const startRoom = () => {
  //   // setall_users_ready([...all_users_ready,true]);
  //   setall_users_ready((prevall_users_ready) => {
  //     const updatedValue = {...prevall_users_ready, all_users_ready: true};
  //     return updatedValue;
  //   })
  //   const liveroomRef = ref(database, "rooms/" + from + "/live/");
  //   set(liveroomRef, { is_live: true });
  //   showproblems();
  // }

  const user_ready = () => {
    const user_ready_Ref = ref(database, "rooms/" + from + "/user_ready");

    if (is_user_Ready === false) {
      const new_user_ready_ref = push(user_ready_Ref);
      set(new_user_ready_ref, {
        uuid: auth.currentUser.uid,
      });
    }
    setshow_ready_btn(false);
    if(all_users_ready === false){
      const formDataCopy = { message: auth.currentUser.displayName + " is ready 🎉" };
      formDataCopy.user = "admin"

      const postListRef = ref(database, "rooms/" + from + "/messages");
      const newPostRef = push(postListRef);
      set(newPostRef, formDataCopy);

      let objDiv = document.getElementById("msg");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    check_all_users_ready();
  };

  const leaderboard = () => {
    setshow_leaderboard(true);
  };

  const [leftWidth, setLeftWidth] = useState(30); // Initial left panel width (%)
  const [centerWidth, setCenterWidth] = useState(40); // Initial center panel width (%)
  const [isDraggingLeft, setIsDraggingLeft] = useState(false);
  const [isDraggingRight, setIsDraggingRight] = useState(false);

  const handleMouseDownLeft = () => setIsDraggingLeft(true);
  const handleMouseDownRight = () => setIsDraggingRight(true);

  const handleMouseMove = (e) => {
    if (isDraggingLeft) {
      const newLeftWidth = (e.clientX / window.innerWidth) * 100;
      setLeftWidth(newLeftWidth);
      setCenterWidth(70 - newLeftWidth);
    } else if (isDraggingRight) {
      const newCenterWidth = ((e.clientX / window.innerWidth) * 100) - leftWidth;
      setCenterWidth(newCenterWidth);
    }
  };

  const handleMouseUp = () => {
    setIsDraggingLeft(false);
    setIsDraggingRight(false);
  };

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);


  return (
    <>
      <div className="flex flex-col min-w-[360px] h-[100vh]">
        <div className="h-[100vh]">
          <div class="flex h-full w-full flex-col">
            <Navbar />
            <div className="flex flex-grow w-full overflow-y-hidden">
              <div class="flex h-full w-full min-w-[800px] dark:bg-dark-layer-bg" onMouseMove={handleMouseMove}>
                <div class="overflow-auto ml-2 mr-1 my-2" style={{ width: `${leftWidth}%` }} data-theme="halloween">
                  <div class="relative flex h-full flex-col">
                    <div className="mx-3">
                      {show_problems && (
                        <div className="tabs flex flex-row justify-center items-center" data-theme="halloween">
                          <a
                            className="tab tab-md tab-lifted tab-active "
                            onClick={showproblems}
                          >
                            Problems
                          </a>
                          <a
                            className="tab tab-md tab-lifted tab-active "
                            onClick={showproblems}
                          >
                            Submissions
                          </a>
                          <a
                            className="tab tab-md tab-lifted tab-active "
                            onClick={showproblems}
                          >
                            Discussion
                          </a>
                        </div>
                      )}

                      {(show_problems || already_started) && isroom_user ? (
                        <>
                          <div
                            className="tabs tabs-boxed flex flex-row justify-center items-center w-full"
                            data-theme="forest"
                          >
                            {current_ques.id === room_questions[0].id ? (
                              <a
                                className="tab tab-lifted tab-active ml-12"
                                onClick={() => onChangeques(0)}
                              >
                                Q 1
                              </a>
                            ) : (
                              <a
                                className="tab tab-lifted"
                                onClick={() => onChangeques(0)}
                              >
                                Q 1
                              </a>
                            )}

                            {current_ques.id === room_questions[1].id ? (
                              <a
                                className="tab tab-lifted tab-active"
                                onClick={() => onChangeques(1)}
                              >
                                Q 2
                              </a>
                            ) : (
                              <a
                                className="tab tab-lifted"
                                onClick={() => onChangeques(1)}
                              >
                                Q 2
                              </a>
                            )}

                            {current_ques.id === room_questions[2].id ? (
                              <a
                                className="tab tab-lifted tab-active"
                                onClick={() => onChangeques(2)}
                              >
                                Q 3
                              </a>
                            ) : (
                              <a
                                className="tab tab-lifted"
                                onClick={() => onChangeques(2)}
                              >
                                Q 3
                              </a>
                            )}

                            {current_ques.id === room_questions[3].id ? (
                              <a
                                className="tab tab-lifted tab-active"
                                onClick={() => onChangeques(3)}
                              >
                                Q 4
                              </a>
                            ) : (
                              <a
                                className="tab tab-lifted"
                                onClick={() => onChangeques(3)}
                              >
                                Q 4
                              </a>
                            )}
                          </div>
                          <div
                            class="overflow-auto mb-20 pt-5"
                            data-theme="halloween"
                          >
                            <u>
                              <center>
                                <h1 className="text-4xl max-lg:text-2xl max-md:text-xl">
                                  {current_ques.title} 
                                </h1>
                                <br></br>
                                <div className="badge w-24 h-10 badge-primary badge-outline">{current_ques.level}</div>
                              </center>
                            </u>
                            <br></br>
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                              }}
                              className="text-lg max-lg:text-base max-md:text-sm"
                            >
                              {current_ques.description}
                            </p>
                            <br></br>
                            <div
                              className="card w-66 bg-base-100 shadow-xl"
                              data-theme="forest"
                            >
                              <div className="card-body">
                                <b className="text-lg max-lg:text-base max-md:text-sm">Sample Input : </b>
                                <div className="text-lg max-lg:text-base max-md:text-sm">{current_ques.sample_input &&
                                  current_ques.sample_input
                                    .split("\n")
                                    .map((si) => (
                                      <p> {si.replace('"', "")} </p>
                                    ))}</div>
                              </div>
                            </div>
                            <br></br>
                            <div
                              className="card w-66 bg-base-100 shadow-xl"
                              data-theme="forest"
                            >
                              <div className="card-body">
                                <b className="text-lg max-lg:text-base max-md:text-sm">Sample Output : </b>
                                <div className="text-lg max-lg:text-base max-md:text-sm">{current_ques.sample_output}</div>
                              </div>
                            </div>
                            <br></br>
                            <div
                              className="card w-66 bg-base-100 shadow-xl"
                              data-theme="forest"
                            >
                              <div className="card-body">
                                <b className="text-lg max-lg:text-base max-md:text-sm">Constraints : </b>

                                <div className="text-lg max-lg:text-base max-md:text-sm">{current_ques.constraints &&
                                  current_ques.constraints
                                    .split("\n")
                                    .map((co) => (
                                      <p>
                                        {co.replaceAll("$", "").split("^")[0]}
                                        <sup>
                                          {co.replaceAll("$", "").split("^")[1]}
                                        </sup>
                                      </p>
                                    ))}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : show_submissions ? (
                        <Submissions />
                      ) : (
                        <div className="px-2 pt-5 mb-20">
                          <center className="text-xl max-lg:text-lg max-md:text-base mt-4">Welcome to Peercode.tech</center>
                          <br>
                          </br>
                          <h1 className="text-lg max-lg:text-base max-md:text-sm mt-1">The most engaging and interactive
                            platform for learning Data Structures and Algorithms.
                            🧠 </h1>
                          <br></br>
                          <h1 className="text-lg max-lg:text-base max-md:text-sm mt-1">🏡You can create rooms, invite your buddies 👫and
                            solve DSA problems together. See who solves all the
                            problems first and emerges victorious. Help each other
                            in excelling by discussing solutions in the chat
                            section. </h1>
                          <br></br>
                          <h1 className="text-lg max-lg:text-base max-md:text-sm mt-1">Join public 📢 rooms and compete with other
                            players. Moreover, make new connections, discuss
                            problems, jobs, interviews, advancements in tech
                            whatever you like.</h1>

                          <br></br>
                          
                          <h1 className="text-lg max-lg:text-base max-md:text-sm mt-1">Also, check out Data Structures and Algorithms
                            problems 📝 to practice on the problems page.
                            More problems will be added soon!
                            <br></br>
                            Let us know if you have
                            any feedback or run into any issues by either filling
                            this form -
                            <center className="my-3"><a href="https://docs.google.com/forms/d/e/1FAIpQLSeZLaUNOP5jay4j-JvZnaVr0XlRIASKFRisxDBItW4o5nU18A/viewform?usp=sf_link" target="true"><button className="btn max-md:btn-sm" data-theme="light">Feedback Form</button></a></center>
                            or mailing us at team@peercode.tech. We will be hanging around in some rooms, hope to
                            see you around!</h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="w-[5px] bg-gray-400 cursor-col-resize flex items-center justify-center"
                  onMouseDown={handleMouseDownLeft}
                >
                  <div className="flex flex-col space-y-1">
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                  </div>
                </div>

                <div class="overflow-auto mx-2 my-2" style={{ width: `${centerWidth}%` }} data-theme="halloween" >
                  <div class="relative flex h-full flex-col">
                    <div class="flex-grow min-h-0">
                      <div class="flex h-full flex-col">
                        <div class="flex h-full flex-col">
                          <div class="relative flex h-full flex-col overflow-hidden rounded">
                            <div class="flex h-9 items-center rounded-t pr-4">
                              {/* <div className="grid flex-grow h-full card bg-base-300 rounded-box place-items-center" data-theme="halloween"> */}
                              <div class="mr-auto flex flex-nowrap items-center gap-3">
                                <div>
                                  <div className="ml-12 text-black">
                                    <LanguagesDropdown
                                      onSelectChange={onSelectChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {show_leaderboard && (
                              <>
                                <div class="flex w-96">
                                  <input
                                    type="checkbox"
                                    id="my-modal"
                                    className="modal-toggle"
                                  />
                                  <div className="modal">
                                    <div className="modal-box">
                                      <div className="justify-end justify-up modal-action">
                                        <label
                                          htmlFor="my-modal"
                                          className="btn btn-sm"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </label>
                                      </div>
                                      <div className="overflow-x-auto">
                                        <table className="table  w-full">
                                          <thead>
                                            <tr>
                                              <th>Rank</th>
                                              <th>Member</th>
                                              <th>{room_questions[0].title}</th>
                                              <th>{room_questions[1].title}</th>
                                              <th>{room_questions[2].title}</th>
                                              <th>{room_questions[3].title}</th>
                                              <th>Points</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {leaderboard_list
                                              .sort((a, b) =>
                                                a.points < b.points ? 1 : -1
                                              )
                                              .map((ll, index) => (
                                                <>
                                                  <tr>
                                                    {index === 0 ? (
                                                      <th>🥇</th>
                                                    ) : index === 1 ? (
                                                      <th>🥈</th>
                                                    ) : index === 2 ? (
                                                      <th>🥉</th>
                                                    ) : (
                                                      <th>{index + 1}</th>
                                                    )}

                                                    <td>{ll.username}</td>
                                                    {ll.q1 === "" ? (
                                                      <td>-</td>
                                                    ) : (
                                                      <td>{ll.q1}</td>
                                                    )}
                                                    {ll.q2 === "" ? (
                                                      <td>-</td>
                                                    ) : (
                                                      <td>{ll.q2}</td>
                                                    )}
                                                    {ll.q3 === "" ? (
                                                      <td>-</td>
                                                    ) : (
                                                      <td>{ll.q3}</td>
                                                    )}
                                                    {ll.q4 === "" ? (
                                                      <td>-</td>
                                                    ) : (
                                                      <td>{ll.q4}</td>
                                                    )}
                                                    <td>{ll.points}</td>
                                                  </tr>
                                                </>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <div class="flex flex-1 flex-col overflow-hidden mt-2">
                              <div class="flex-1 overflow-hidden">
                                <div className="h-full w-full">
                                  {show_console === false ? (
                                    <>
                                      {current_ques === room_questions[0] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques1_code}
                                          width="100%"
                                          height="100%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[1] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques2_code}
                                          width="100%"
                                          height="100%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[2] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques3_code}
                                          width="100%"
                                          height="100%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[3] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques4_code}
                                          width="100%"
                                          height="100%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : (
                                        <AceEditor
                                          mode="c_cpp"
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value=""
                                          width="100%"
                                          height="100%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {current_ques === room_questions[0] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques1_code}
                                          width="100%"
                                          height="50%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[1] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques2_code}
                                          width="100%"
                                          height="50%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[2] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques3_code}
                                          width="100%"
                                          height="50%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : current_ques === room_questions[3] ? (
                                        <AceEditor
                                          mode={editor_mode}
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value={ques4_code}
                                          width="100%"
                                          height="50%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      ) : (
                                        <AceEditor
                                          mode="c_cpp"
                                          theme="terminal"
                                          onChange={onChange}
                                          name="UNIQUE_ID_OF_DIV"
                                          value="Type here"
                                          width="100%"
                                          height="50%"
                                          fontSize={16}
                                          placeholder=""
                                          editorProps={{
                                            $blockScrolling: true,
                                          }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                  <br></br>
                                  {show_console && (
                                    <div class="overflow-auto">
                                      <div className="card w-full h-60 bg-base-100 shadow-xl animate-fadeIn">
                                        <div className="card-body">
                                          {processing ? (
                                            <>
                                              <center>
                                                <h2 className="card-title ">
                                                  Processing Test Cases...
                                                </h2>
                                              </center>
                                              <RoomSpinner />
                                            </>
                                          ) : !processing && curr_status ? (
                                            runAllVar ? 
                                            <>
                                              <center>
                                                <h2 className="card-title">
                                                  Processing Test Cases...
                                                </h2>
                                              </center>
                                              <br></br>
                                              <div className="alert alert-success  animate-bounce">
                                                <center>
                                                  {" "}
                                                  <span>
                                                    ✅ Yayy, All Test cases
                                                    Passed!
                                                  </span>
                                                </center>
                                              </div>
                                            </>: <>
                                            <div>
                                                      <div className="alert alert-success  animate-bounce">
                                                        <center>
                                                          {" "}
                                                          <span>
                                                            ✅ Yayy, Test Case
                                                            Passed!
                                                          </span>
                                                        </center>
                                                      </div>
                                                      <br></br>
                                                      <h1>Input : </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        {current_ques.sample_input.split(
                                                          "\n"
                                                        ).map((si) => (
                                                          <p>
                                                            {" "}
                                                            {si.replace(
                                                              '"',
                                                              ""
                                                            )}{" "}
                                                          </p>
                                                        ))}
                                                      </div>
                                                      <h1>Expected Output: </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        <span>
                                                          {
                                                            current_ques.sample_output
                                                          }
                                                        </span>
                                                      </div>
                                                      <h1>Your Output : </h1>
                                                      <div
                                                        className="alert"
                                                        data-theme="halloween"
                                                      >
                                                        <span>
                                                          {
                                                            current_ques.sample_output
                                                          }
                                                        </span>
                                                      </div>
                                                    </div></>
                                          ) : tc_failed ? (
                                            <div>
                                              <h1>Test Case Failed!</h1>
                                              <br></br>
                                              <h1>Input : </h1>
                                              <div
                                                className="alert"
                                                data-theme="halloween"
                                              >
                                                {failed_testcase.Input.split(
                                                  "\n"
                                                ).map((si) => (
                                                  <p> {si.replace('"', "")}</p>
                                                ))}
                                              </div>
                                              <h1>Expected Output: </h1>
                                              <div
                                                className="alert"
                                                data-theme="halloween"
                                              >
                                                <span>
                                                  {
                                                    failed_testcase.Expected_output
                                                  }
                                                </span>
                                              </div>
                                              <h1>Your Output : </h1>
                                              <div
                                                className="alert"
                                                data-theme="halloween"
                                              >
                                                <span>
                                                  {failed_testcase.User_Output}
                                                </span>
                                              </div>
                                            </div>
                                          ) : runtime_error ? (
                                            <div>
                                              <h1>Oops! Some error occured!</h1>
                                              <h1>Input :</h1>
                                              <div
                                                className="alert"
                                                data-theme="halloween"
                                              >
                                                <span>
                                                  {errors.Input.slice(0, 20) +
                                                    "....."}
                                                </span>
                                              </div>
                                              <br></br>
                                              <h1>Error :</h1>
                                              <div
                                                className="alert"
                                                data-theme="halloween"
                                              >
                                                <span>
                                                  {errors.Standard_Error ===
                                                    "nullValue"
                                                    ? errors.description
                                                    : errors.Standard_Error}
                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {" "}
                                              <center>
                                                <h2 className="text-xl">
                                                  You have not submitted any
                                                  code.
                                                </h2>
                                              </center>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex items-end">
                      <div className="flex flex-1 flex-nowrap items-center gap-4 m-2">
                        <button
                          className="btn text-md max-sm:text-[12px]"
                          data-theme="light"
                          onClick={open_Console}
                        >
                          Console
                        </button>
                      </div>
                      <div className="relative ml-auto flex items-center gap-3 m-2">
                        {current_ques && room_questions && (
                          <>
                          <button
                            onClick={() => {
                              const index = room_questions.findIndex((q) => q.id === current_ques.id);
                              if (index >= 0) {
                                const compileFunctions = [ques1_code, ques2_code, ques3_code, ques4_code];
                                compile(compileFunctions[index], false);
                              }
                            }}
                            className="btn relative text-md max-sm:text-[12px] flex flex-row-reverse"
                            data-theme="light"
                          >
                            {processing && !runAllVar ? "Processing..." : "Run"}
                          </button>
                          
                          <button
                            onClick={() => {
                              const index = room_questions.findIndex((q) => q.id === current_ques.id);
                              if (index >= 0) {
                                const compileFunctions = [ques1_code, ques2_code, ques3_code, ques4_code];
                                compile(compileFunctions[index], true);
                              }
                            }}
                            className="btn relative text-md max-sm:text-[12px] flex flex-row-reverse"
                            data-theme="light"
                          >
                            {processing && runAllVar ? "Processing..." : "Submit"}
                          </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w-[5px] bg-gray-400 cursor-col-resize flex items-center justify-center"
                  onMouseDown={handleMouseDownRight}
                >
                  <div className="flex flex-col space-y-1">
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                    <span className="h-1 w-1 rounded-full" style={{ backgroundColor: '#717070' }}></span>
                  </div>
                </div>


                <div class="overflow-auto mx-1 my-2" style={{ width: `${100 - leftWidth - centerWidth}%` }} data-theme="halloween">
                  <div class="relative flex h-full flex-col">
                    <div className="card w-[calc(23% - 4px)] bg-base-100 shadow-xl">
                      <div className="card-body">
                        <div className="card-title text-lg max-lg:text-base max-md:text-sm">{room_name} <CopyLinkButton link={"https://peercode.tech/rooms/" + from} /></div>
                        <div className="text-sm ml-4 breadcrumbs max-w-md">
                          <ul>
                            {room_users.map((ru) => (
                              <li>
                                <div className="text-lg max-lg:text-base max-md:text-sm">{ru.replace("@gmail.com", "")}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <br></br>
                        <div className="card-actions  flex flex-row">
                          <div>
                            {/* {isroom_user && loggedIn ? (
                              <button
                                type="submit"
                                className="btn btn-secondary text-md max-md:text-[12px]"
                                onClick={leaveRoom}
                              >
                                Leave Room
                              </button>
                            ) : (!isroom_user && loggedIn ?
                              <button
                                type="submit"
                                className="btn btn-success text-md max-md:text-[12px]"
                                onClick={joinroom}
                              >
                                Join Room
                              </button>
                               : (!isroom_user && !loggedIn ?
                               <Link to="/sign-in"><button
                                type="submit"
                                className="btn btn-success"
                                
                              >
                                Join Room
                              </button></Link>
                             : <></>))} */}
                            {isroom_user && loggedIn &&
                              <button
                                type="submit"
                                className="btn  btn-secondary"
                                onClick={leaveRoom}
                              >
                                Leave Room
                              </button>}

                            {isroom_user === false && loggedIn &&
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={joinroom}
                              >
                                Join Room
                              </button>}

                            {isroom_user === false && loggedIn === false &&
                              <Link to="/sign-in"><button
                                type="submit"
                                className="btn btn-success"

                              >
                                Join Room
                              </button></Link>}

                          </div>

                          {isroom_user && show_ready_btn && is_user_Ready===false && !already_started && room_timer_completed === false ? (
                            <button
                              className="btn btn-primary"
                              onClick={user_ready}
                            >
                              Ready
                            </button>
                          ) : (
                            <></>
                          )}
                          <div>
                            <label
                              htmlFor="my-modal"
                              className="btn btn-primary"
                              onClick={leaderboard}
                            >
                              Leaderboard
                            </label>
                          </div>
                          <div>
                            {true &&
                              (all_users_ready ? (
                                <Timer from={from} start={true} />
                              ) : room_timer_completed === true ? (
                                <button className="btn">This room has ended.</button>
                              ) : already_started ? ( 
                                <Timer from={from} start={false} />
                              ):
                              (
                                <button className="btn">Timer will start when everyone is ready.</button>
                              ))}
                          </div>
                          <></>
                        </div>
                      </div>
                    </div>
                    <div class="rounded-lg flex-grow min-h-0 pl-2 py-2 mt-2">
                      <div class="flex h-full flex-col">
                        <div class="flex h-full flex-col">
                          <div class="overflow-y-auto" id="msg">
                            {/* <div class="bottom-24"> */}

                            <div class="rounded-lg shadow-md">
                              {/* <div class="overflow-y-auto max-h-64"> */}
                              <div class="flex flex-col space-y-4">
                                {all_messages.map((m) =>
                                  loggedIn && m.user === auth.currentUser.displayName ? (
                                    <div class="flex items-end justify-end mr-2">
                                      <div class="bg-green rounded-lg p-2">
                                        <p class="text-mono text-l text-black">
                                          {m.message}
                                        </p>
                                        <small class="text-black">
                                          from: {m.user}
                                        </small>
                                      </div>
                                    </div>
                                  ) : ( m.user !== "admin" ?
                                    <div class="flex items-start ml-2">
                                      <div class="bg-purple rounded-lg p-2">
                                        <p class="text-l text-mono  text-white">
                                          {m.message}
                                        </p>
                                        <small class="text-sm text-white">
                                          from: {m.user}
                                        </small>
                                      </div>
                                    </div>
                                  : (
                                    <center><div class="flex items-start ml-2">
                                    <div class="bg-black rounded-lg p-2">
                                      <p class="text-xl text-mono text-white">
                                        {m.message}
                                      </p>
                                    </div>
                                  </div></center>
                                  ))
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-1 flex items-end w-full">
                      {loggedIn ? <form onSubmit={onSubmit} className="w-full">
                        {/* <input
                        type="text"
                        class="w-full border rounded-lg px-4 py-2"
                        placeholder="Type your message..."
                      /> */}
                        <div className="flex flex-row">
                          <input
                            type="text"
                            id="message"
                            value={message}
                            placeholder="Type here"
                            class="w-full border rounded-lg px-4 py-2 mr-2"
                            onChange={onChange2}
                          />
                          <button type="submit" className="btn btn-primary">
                            Send
                          </button>
                        </div>
                      </form> :
                        <div className="flex flex-row w-full">
                          <input
                            type="text"
                            id="message"
                            value={message}
                            placeholder="Type here"
                            class="w-full border rounded-lg px-4 py-2 mr-2"
                            onChange={onChange2}
                          />
                          <Link to="/sign-in"><button type="submit" className="btn btn-primary">
                            Send
                          </button></Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RoomForm;
