import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import { getAuth } from "firebase/auth";
import { onValue } from "firebase/database";
import RoomForm from "../components/RoomForm";
import axios from "axios";
import Spinner from "../components/Spinner";
import Navbar from "../components/Navbar/Navbar"
import { useAuthStatus } from "../hooks/useAuthStatus";
function HomePage() {
  const auth = getAuth();
  const database = getDatabase();
  const [all_questions, set_all_questions] = useState([]);
  const [room_questions, setroom_questions] = useState([]);
  const [q, setq] = useState([]);
  const [all_rooms, setall_rooms] = useState([]);
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const [room_live_list, setroom_live_list] = useState([])

  const { loggedIn, checkingStatus } = useAuthStatus();

  useEffect(() => {
    axios
      .get("https://ed-tech-backend-eight.vercel.app/problemsInfo/", {
        mode: "no-cors",
      })
      .then((response) => setq(response.data));

    const all_rooms_ref = ref(database, "rooms/");

    onValue(all_rooms_ref, (snapshot) => {
      const data = snapshot.val();
      // console.log(data)
      let rooms = [];
      let live_rooms_list = []
      // let topic_Arr = []
      for (let d in data) {
        rooms.push([d, data[d].name.name]);
        if (rooms.length === 5) {
          break;
        }
        live_rooms_list.push(data[d].live.is_live)
      }
      setall_rooms(rooms);
      setroom_live_list(live_rooms_list)
    });

  }, []);


  const [roomCreate, setRoomCreate] = useState(false);
  const [roomTopics, setRoomTopics] = useState([]);
  const createRoom = async (e) => {
    setRoomCreate(true);
  };

  let temp_topics = [];

  const onclick = () => {
    const db_ref = ref(database, "contest-components/" + "entry");
    const statusRef = push(db_ref);
    set(statusRef, {
      id: auth.currentUser.uid,
    });
  };

  const joinroom = async ({ from }) => {
    const userData = auth.currentUser.uid;
    const email = auth.currentUser.email;
    const roomuserRef = ref(database, "rooms/" + from + "/users");
    const newroomusersRef = push(roomuserRef);
    set(newroomusersRef, {
      uuid: userData,
      email: email,
      name: auth.currentUser.displayName,
    });
    // setisroom_user(true);
  };

  return (
    <div data-theme="halloween">
      <Navbar />
      {/* <center > */}
      {/* <div className="alert alert-success">
          <center className="ml-22">
          <b>Register for the contest #2: </b>
            <Link to={`/contest-registration`}><button className="btn btn-primary">Register Here</button></Link> */}
      {/* <b>See Winners of the contest here : </b>
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal w-88 overflow-x-auto">
              <div className="modal-box">
                <div className="justify-end justify-up modal-action">
                  <label htmlFor="my-modal" className="btn btn-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </label>
                </div>
                <br></br>
                <b>
                  <h3>
                    Congratulations to all the winners. We will soon be
                    contacting you for delivering your prizes. For others, we
                    will be hosting contest every weekend, so stay tuned.
                    Moreover, you can create rooms and practice/compete DSA
                    problems with your friends/strangers.
                  </h3>
                </b>
                <div className="overflow-x-auto w-full">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Prize</th>
                        <th>Company/College</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Naveen Singh</td>
                        <td>🥇</td>
                        <td>Bits Goa</td>
                      </tr>
                      <tr>
                        <td>Yash Kapoor</td>
                        <td>🥈</td>
                        <td>Amazon</td>
                      </tr>
                      <tr>
                        <td>Sahaj Arora</td>
                        <td>🥉</td>
                        <td>LPU</td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <label htmlFor="my-modal" className="btn btn-outline btn-primary">
              See Winners
            </label> */}
      {/* </center> */}

      {/* Open the modal using ID.showModal() method */}
      <div className="flex flex-row px-4 py-4">
        <div className="flex flex-col max-lg:hidden max-md:hidden max-sm:hidden">
          <div className="grid  flex-grow h-full">
            <div className="card w-88 bg-base-100 shadow-xl">
              {loggedIn ? (<div className="card-body">
                {q.length > 0 ? (
                  <Link to={`/roomcreate`} state={{ q: q }}>
                    <button className="btn btn-outline btn-info  btn-sm">
                      Create Your Room
                    </button>
                  </Link>
                ) : (
                  ""
                )}
                {/* <Link to="/enter-room">
                  {" "}
                  <button className="btn  btn-outline btn-info btn-sm">
                    Enter A Room
                  </button>
                </Link> */}
                <center>
                  <h2 className="card-title">Recently Active Rooms</h2>
                </center>
                <center>{roomCreate && <RoomForm />} </center>

                {all_rooms.map((r, index) => (
                  <Link to={`/rooms/${r[0]}`} state={{ from: r[0] }}>
                    <div className="card max-w-66 w-66 bg-base-100 shadow-xl h-relative p-4" data-theme="forest">
                      <center>
                        <p class="font-semibold leading-normal">
                          Room id - {r[1]}
                        </p>
                        {room_live_list[index] === true ? <center className="flex flex-row mt-2 ml-16 mb-2">🟢 Live</center> : <p className=" mt-2 mb-2">🟡 Not yet started</p>}
                      </center>
                    </div>
                  </Link>
                ))}
              </div>) : (
                <div className="card-body">
                  <Link to={'/sign-in'} >
                    <button className="btn btn-outline btn-info h-fit p-2">
                      Please Login/SignUp to access rooms.
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="divider divider-horizontal max-lg:hidden max-md:hidden max-sm:hidden"></div>
        <div className="flex flex-col w-full">
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead data-theme="forest">
                <tr>
                  <th className="text-[15px] max-sm:text-[13px]">Problem Id</th>
                  <th className="text-[15px] max-sm:text-[13px]">Title</th>
                  <th className="text-[15px] max-sm:text-[13px]">Level</th>
                  <th className="text-[15px] max-sm:text-[13px]">Topics</th>
                </tr>
              </thead>
              <tbody>
                {q.length > 0 ? (
                  q
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map((qu) => (
                      <>
                        <tr style={{ borderBottom: '2px solid #3d3d3d' }}>
                          <td className="font-mono justify-center items-center text-md max-sm:text-sm">
                            <Link
                              to={`/problems/${qu.question_id}`}
                              state={{
                                id: qu.question_id,
                                from_room: "false",
                                room_id: "None",
                                ques_id: ""
                              }}
                              className="mt-10"
                            >
                              {qu.question_id}
                            </Link>
                          </td>
                          <td className="font-mono justify-center items-center text-md max-sm:text-sm" >
                            <Link
                              to={`/problems/${qu.question_id}`}
                              state={{
                                id: qu.question_id,
                                from_room: "false",
                                room_id: "None",
                                ques_id: ""
                              }}
                              className="mt-10"

                            >
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[300px] max-sm:max-w-[200px]">{qu.title}</div>
                            </Link>
                          </td>
                          <td className="font-mono justify-center items-center text-md max-sm:text-sm">{qu.level[0].toUpperCase() + qu.level.slice(1)}</td>
                          <td className="font-mono justify-center items-center text-md max-sm:text-sm">{String(qu.tags).split(",").map((t) => <div className="badge badge-primary badge-outline text-l mr-2 w-fit h-fit p-2">{t[0].toUpperCase() + t.slice(1)}</div>)}</td>
                        </tr>
                      </>
                    ))
                ) : (
                  <Spinner />
                )}
              </tbody>
            </table>
            <tfoot>
              <tr></tr>
            </tfoot>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
