import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { CheckCircle2 } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import RoomScreenshot from "../components/RoomScreenshot";
import logo from '../assets/logo-bg.png'

function Home() {
  // const [q, setq] = useState([]);
  // const [array_ques, setarray_ques] = useState([]);
  // const [dp_ques, setdp_ques] = useState([]);
  // const [string_ques, setstring_ques] = useState([]);
  // const [searching_ques, setsearching_ques] = useState([]);
  // const [displayText, setDisplayText] = useState("");
  let text =
    "AI Powered Next Generation Platform for DSA Interview preparation!";
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const auth = getAuth();
  // const database = getDatabase();

  const checklistItems = [
    {
      title: "Atlassian Statuspage",
      description:
        "Create rooms and practice problems with your friends.",
    },
    {
      title: "Jira",
      description:
        "Join public rooms to compete and practice with coders from around the world.",
    },
    {
      title: "Pagerduty",
      description:
        "Filter problems with difficulty and solve them together.",
    },
    {
      title: "Zendesk",
      description:
        "Utilize the chat space to discuss solutions, approaches and hints to solve problems.",
    },
    {
      title: "And many more....",
      description:
        "Check out the upcoming jobs section to apply to recent openings.",
    },
    {
      title: "And many more....",
      description:
        "Languages Supported : C++, Java and Python. More languages to be added soon..",
    },
  ];

  // useEffect(() => {
  //   axios
  //     .get("https://linked-list-backend.herokuapp.com/problemsInfo/", {
  //       mode: "no-cors",
  //     })
  //     .then((response) => setq(response.data));

  //   // console.log(q);
  // }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (currentIndex < text.length) {
  //       setDisplayText((prevText) => prevText + text[currentIndex]);
  //       setCurrentIndex((prevIndex) => prevIndex + 1);
  //     } else {
  //       setDisplayText("");
  //       setCurrentIndex(0);
  //     }
  //   }, 100); // You can adjust the delay between letters here

  //   return () => clearTimeout(timer);
  // }, [currentIndex, displayText]);

  //   q.map((qu) => {
  //     if(qu.question_id === 33 || qu.question_id === 46 || qu.question_id === 29 || qu.question_id === 52){
  //       array_ques.push(qu)
  //       // console.log(qu)
  //     }
  // })

  // const onclick = () => {
  //   const db_ref = ref(database, "contest-components/" + "entry");
  //   const statusRef = push(db_ref);
  //   set(statusRef, {
  //     id: auth.currentUser.uid,
  //   });
  // };

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center mt-6 lg:mt-20">
        <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide text-white">
          Master data structures and algorithms together.{" "}
          <br></br>
          <span className="text-2xl sm:text-4xl lg:text-4xl text-center  text-white">
            {" "}
            An intuitive way to practice problem solving.
          </span>
        </h1>
        <div className="mt-2" id="integrations">
          {/* 
      <div className="flex flex-wrap w-full justify-center tracking-wide">
        <div className="pt-12 w-full lg:w-1/2">
          {checklistItems.map((item, index) => (
            <div key={index} className="flex mb-12">
              <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
                <CheckCircle2 />
              </div>
              <div>
                <h5 className="mt-1 mb-2 text-xl">{item.description}</h5>
              </div>
            </div>
          ))}
        </div>
      </div> */}

          <div className="relative mt-20 border-white border-white" id="features">
            <div className="text-center">
            </div>
            <div className="flex flex-wrap mt-10 lg:mt-20 px-[30px]">
              {checklistItems.map((feature, index) => (
                <div key={index} className="w-full sm:w-1/2 lg:w-1/3 border-white">
                  <div className="flex">
                    <div className="flex mx-4 h-10 w-10 bg-neutral-900 text-white-700 justify-center items-center rounded-full">
                      <CheckCircle2 />
                    </div>
                    <div>
                      {/* <h5 className="mt-1 mb-6 text-xl">{feature.text}</h5> */}
                      <p className="text-md mb-20 text-white text-2xl ">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center my-10">
          <Link to={"/allrooms"}><a
            className="bg-white py-3 px-4 mx-3 rounded-md text-black"
          >
            Join Rooms
          </a></Link>
          <Link to={"/problems"}><a
            className="bg-white py-3 px-4 mx-3 rounded-md text-black"
          >
            Solve Problems
          </a></Link>


        </div>
        {/* <Animation /> */}
        {/* <div className="flex mt-10 justify-center">
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */}

        <RoomScreenshot />

      </div>
      <footer className="footer footer-center  p-10">
        <aside>
          {/* <svg
      width="50"
      height="50"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      className="inline-block fill-current">
      <path
        d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> */}
          <img
            className='text-center mx-auto h-[200px]'
            src={logo}
          />
          <p className="font-bold">
            peercode.tech
            <br />

          </p>
          <p>Copyright © {new Date().getFullYear()} - All right reserved</p>
        </aside>
        <nav>
          <div className="grid grid-flow-col gap-4">
            <a
              href="https://www.linkedin.com/company/peercodetech/"
              target="_blank"
              className="text-primary"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a
              href="https://x.com/peercodetech"
              target="_blank"
              className="text-primary"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a
              href="team@peercode.tech"
              target="_blank"
              className="text-primary"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faAt} size="2x" />
            </a>
          </div>
        </nav>
      </footer>
    </>
  );
}

export default Home;


{/* <Navbar />
<center>
  <section class="relative overflow-hidden mt-0">
    <div class="relative z-10 container px-4 mx-auto">
      <div class="flex flex-wrap lg:items-center -m-8 ">
        <div class="w-96 md:w-1/2 p-8 lg-w-44">
          <div>
            <h3 className="text-4xl md:text-4xl xl:text-4xl font-semibold font-heading">
              {displayText}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</center> */}