import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import { database } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { onValue } from "firebase/database";
import { Checkbox } from "@material-tailwind/react";
import RoomForm from "../components/RoomForm";
import axios from "axios";
import { data } from "autoprefixer";
import Spinner from "../components/Spinner";
import Navbar from "../components/Navbar/Navbar"
import Liveicon from "../components/Liveicon"
import { useAuthStatus } from "../hooks/useAuthStatus";
import CopyLinkButton from "../components/CopyLinkButton";

function AllRooms() {
  const auth = getAuth();
  const database = getDatabase();
  const [all_rooms, setall_rooms] = useState([]);
  const [q, setq] = useState([]);
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const [room_live_list, setroom_live_list] = useState([])

  const { loggedIn, checkingStatus } = useAuthStatus();

  useEffect(() => {

    axios
      .get("https://ed-tech-backend-eight.vercel.app/problemsInfo/", {
        mode: "no-cors",
      })
      .then((response) => setq(response.data));

    const all_rooms_ref = ref(database, "rooms/");

    onValue(all_rooms_ref, (snapshot) => {
      const data = snapshot.val();
      // console.log(data)
      let rooms = [];
      let live_rooms_list = []
      // let topic_Arr = []
      for (let d in data) {
        rooms.push([d, data[d].name.name, data[d].level.level]);
        live_rooms_list.push(data[d].live.is_live)
      }
      setall_rooms(rooms);
      setroom_live_list(live_rooms_list)
    });

  }, []);




  return (
    <div data-theme="halloween">
      <Navbar />
      <center className="my-[60px]">
        <div className="flex flex-row justify-between w-[90%] items-center">
          <h1 className="mt-2 text-4xl lg:text-4xl mb-2">All Active Rooms</h1>
          {loggedIn && q.length > 0 ? (
            <Link to={`/roomcreate`} state={{ q: q }}>
              <button className="btn btn-primary">
                Create Your Room
              </button></Link>
          ) : <></>}
        </div>
        <div className="overflow-x-auto w-full my-[30px]">
          <table className="table w-[90%]">
            {/* head */}
            <thead data-theme="forest" className="w-full">
              <tr>
                <th className="text-[15px] max-sm:text-[13px]">Room Id</th>
                <th className="text-[15px] max-sm:text-[13px]">Status</th>
                <th className="text-[15px] max-sm:text-[13px]">Difficulty</th>
                <th className="text-[15px] max-sm:text-[13px]">Room Url</th>
                <th className="text-[15px] max-sm:text-[13px]"></th>
              </tr>
            </thead>
            <tbody>
              {all_rooms.map((r, index) => (
                <tr style={{ borderBottom: '2px solid #3d3d3d' }}>
                  <td className="text-mono text-md max-sm:text-sm"><Link to={`/rooms/${r[0]}`} state={{ from: r[0] }}>{r[1]}</Link></td>
                  <td>{room_live_list[index] === true ? <p className="flex flex-row mt-2">🟢 Live</p> : <p>🟡 Not yet started</p>}</td>
                  <td className="text-mono text-md max-sm:text-sm"><div className="badge badge-primary badge-outline text-l mr-2 w-fit h-12">{r[2][0].toUpperCase() + r[2].slice(1)}</div></td>
                  <td className="text-md max-sm:text-sm"><CopyLinkButton link={"https://peercode.tech/rooms/" + r[0]} /></td>
                  <Link to={`/rooms/${r[0]}`} state={{ from: r[0] }}><td className="text-md max-sm:text-sm"><button className="btn btn-primary">Join Room</button></td></Link>
                </tr>))}
            </tbody>
          </table>
        </div>
      </center>
    </div>

  )
}

export default AllRooms

{/* <Link to={`/rooms/${r[0]}`} state={{ from: r[0] }}>
        <div className="card w-full bg-base-100 shadow-xl h-relative" data-theme="forest">
          <center>
            <p class="font-semibold leading-normal mt-8">
              Room id - {r[1]}
            </p>
            {room_live_list[index] === true ? <center className="flex flex-row mt-2"><Liveicon />Live</center> : <p>Not yet started</p>} */}